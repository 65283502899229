import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DashboardContext } from '../../../../components/layout/dashboard-layout/DashboardLayout';
import { SeoService } from '../../../../services/SeoService';
import { AccountSettings } from './components/AccountSettings';
import { Receipts } from './components/Receipts';
import { YourPlan } from './components/YourPlan';

export const AccountPage = () => {
  const dashboardContext = useContext(DashboardContext);

  useEffect(() => {
    dashboardContext.setDashboardLocation('Settings / Account');
  }, [dashboardContext]);

  return (
    <>
      <Helmet>
        <title>{SeoService.getPageTitle('Account Settings')}</title>
      </Helmet>
      <AccountSettings />
      <YourPlan />
      <Receipts />
    </>
  );
};
