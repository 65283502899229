import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SITEMAP } from '../../../config/SITEMAP';
import { AppState } from '../../../redux/store';
import { UsersApi } from '../../../services/api/UsersApi';

type NotificationBarEmailPropsType = {};

export const NotificationBarEmail = (props: NotificationBarEmailPropsType) => {
  const [btnLabel, setBtnLabel] = useState('Re-send verification email');
  const [isBtnDisabled, setBtnDisabled] = useState(false);
  const user = useSelector((state: AppState) => state.user);

  const handleSendEmail = async (e: React.MouseEvent) => {
    setBtnDisabled(true);
    setBtnLabel('Sending...');
    await UsersApi.resendActivationEmail(user.id);
    setBtnLabel('Sent!');
  };

  return (
    <div
      className={classNames({
        'notification-bar': true,
        'notification-bar--warning': true,
      })}
    >
      <div className="content">
        <span className="icon icon-attention"></span>
        <span className="message">Please verify your e-mail ({user.email}) to get full access to our services.</span>
      </div>
      <div className="actions">
        <Link to={SITEMAP.ACCOUNT + '#edit-email'}>Change email</Link>
        <button disabled={isBtnDisabled} onClick={handleSendEmail}>
          {btnLabel}
        </button>
      </div>
    </div>
  );
};
