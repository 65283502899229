import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { PlaceInput } from '../../../components/inputs/PlaceInput';
import { FormControlRow } from '../../../components/inputs/form-parts/FormControlRow';
import { TextInput } from '../../../components/inputs/TextInput';
import { Btn } from '../../../components/inputs/Btn';
import { UtilService } from '../../../services/Util.service';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { InfoRow } from '../../../components/inputs/form-parts/InfoRow';
import { useEffect, useState } from 'react';

type PropsType = {
  handleSubmitSearch: (fullName: string, city: string, recaptchaToken: string) => void;
};

export const SearchForm = (props: PropsType) => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const defaultFullName = urlParams.get('fullName');

  useEffect(() => {
    if (defaultFullName) {
      document.getElementById('city')!.focus();
    }
  }, [defaultFullName]);

  const [recaptchaToken, setRecaptchaToken] = useState<undefined | null | string>(undefined);

  const onChange = (value: string | null) => {
    setRecaptchaToken(value);
  };

  return (
    <Formik
      initialValues={{ fullName: defaultFullName || '', city: '' }}
      validationSchema={Yup.object({
        fullName: Yup.string()
          .required('Full name is mandatory')
          .test('First and last name', 'First and last names are mandatory', (value) => UtilService.checkFullName(value)),
        city: Yup.string().required('City is mandatory'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        if (typeof recaptchaToken === 'string') {
          props.handleSubmitSearch(values.fullName, values.city, recaptchaToken);
        } else {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, submitCount }) => (
        <Form className="scan-form">
          <FormControlRow>
            <TextInput label="Full Name" name="fullName" type="text" placeholder="e.g. John Smith" required />
            <PlaceInput label="City" name="city" placeholder="e.g. Boston, MA" required autoComplete="avoid-chrome-autofill" />
            <Btn type="submit" size="medium" label="Start scan" hasArrow={true} />
          </FormControlRow>
          <FormControlRow>
            <ReCAPTCHA sitekey="6LcJvWQaAAAAADXLe_xhm1JWJ-HOneb1vyOPGYHc" onChange={onChange} />
          </FormControlRow>
          {submitCount > 0 && typeof recaptchaToken !== 'string' && (
            <InfoRow type="error" message="Please confirm that you are not a robot" />
          )}
        </Form>
      )}
    </Formik>
  );
};
