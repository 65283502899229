import { AuthService } from '../Auth.service';
import { post } from './config';
import { FullUserResponse } from './UsersApi';

type AuthCredentials = {
  email: string;
  password: string;
};

export type LoginResponse = {
  access_token: string;
  user: FullUserResponse;
};

type ChangePasswordType = {
  oldPassword: string;
  password: string;
};

type ResetPasswordRequest = {
  email: string;
};

type NewPasswordRequest = {
  password: string;
};

export class AuthApi {
  static login = async (params: AuthCredentials) => await post<LoginResponse>('/auth', params);

  static activateUser = async (userId: string, code: string) => await post<FullUserResponse>(`/auth/activate/${userId}/${code}`);

  static changePassword = async (userId: string, params: ChangePasswordType) =>
    await post<{}>(`/auth/${userId}/change-password`, params, AuthService.getAuthHeader());

  static resetPasswordRequest = async (params: ResetPasswordRequest) => await post<{}>(`/auth/reset-password`, params);

  static resetPassword = async (userId: string, token: string, params: NewPasswordRequest) =>
    await post<{}>(`/auth/reset-password/${userId}/${token}`, params);
}
