import React from 'react';
import { Route, RouteProps, Switch, useLocation } from 'react-router-dom';
import { DashboardLayout } from '../../components/layout/dashboard-layout/DashboardLayout';
import { SITEMAP } from '../../config/SITEMAP';
import { AdminRoute } from '../../routers/AdminRoute';
import { NotFoundPage } from '../not-found/NotFoundPage';
import { AdminStatsPage } from './admin/AdminStatsPage';
import { AdminUsersPage } from './admin/AdminUsersPage';
import { ProfilePage } from './profile/ProfilePage';
import { ResourcesPage } from './resources/ResourcesPage';
import { AccountPage } from './settings/account/AccountPage';
import { ManageProfilesPage } from './settings/manage-profiles/ManageProfilesPage';

export const DashboardRouter = (props: RouteProps) => {
  const { pathname } = useLocation();

  return (
    <DashboardLayout>
      <Switch>
        <Route exact path={SITEMAP.DASHBOARD} component={ProfilePage} />
        <Route path={SITEMAP.RESOURCES} component={ResourcesPage} />
        <Route path={SITEMAP.PROFILE(':profileId')} key={pathname} component={ProfilePage} />
        <Route path={SITEMAP.ACCOUNT} component={AccountPage} />
        <Route path={SITEMAP.MANAGE_PROFILES} component={ManageProfilesPage} />
        <AdminRoute path={SITEMAP.ADMIN_USERS} component={AdminUsersPage} />
        <AdminRoute path={SITEMAP.ADMIN_STATS} component={AdminStatsPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </DashboardLayout>
  );
};
