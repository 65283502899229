import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TextInput } from '../../components/inputs/TextInput';
import { LoginLayout } from '../../components/layout/login-layout/LoginLayout';
import { Btn } from '../../components/inputs/Btn';
import { FormControlRow } from '../../components/inputs/form-parts/FormControlRow';
import { FormSubmitRow } from '../../components/inputs/form-parts/FormSubmitRow';
import { EmailValidator } from '../../services/validators/YupValidators';
import { AuthApi } from '../../services/api/AuthApi';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { SeoService } from '../../services/SeoService';
import { HubspotService } from '../../services/HubspotService';

export const ForgotPasswordPage = () => {
  const [sentToAddress, setTentToAddress] = useState('');

  return (
    <LoginLayout
      title={!sentToAddress ? 'Forgot password?' : 'Check your inbox!'}
      description={
        !sentToAddress ? (
          'Please enter your account e-mail and we will forward you reset instructions.'
        ) : (
          <>
            If you’ve used {sentToAddress} to previously register on Allen Privacy, you will shortly receive a message with re-activation
            link.
            <br />
            <br />
            In case you didn’t get any e-mails from us,{' '}
            <button className="link" onClick={() => HubspotService.showHubspotChat()}>
              click here to get live support
            </button>{' '}
            or drop us an e-mail at <a href="mailto:support@allenprivacy.com">support@allenprivacy.com</a>.
          </>
        )
      }
    >
      <Helmet>
        <title>{SeoService.getPageTitle('Forgot Password')}</title>
      </Helmet>
      {!sentToAddress && (
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            email: EmailValidator,
          })}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            AuthApi.resetPasswordRequest(values)
              .then((res) => setTentToAddress(values.email))
              .catch((error) => {
                if (error.statusCode === 404) setErrors({ email: 'Account with this email does not exist' });
              });
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting, dirty, values, isValid }) => (
            <Form>
              <FormControlRow>
                <TextInput label="E-mail address" placeholder="e.g. johndoe@example.com" name="email" type="email" />
              </FormControlRow>
              <FormSubmitRow>
                <Btn type="submit" label="Reset password" hasArrow={true} loading={isSubmitting} disabled={!isValid} />
              </FormSubmitRow>
            </Form>
          )}
        </Formik>
      )}
    </LoginLayout>
  );
};
