import moment from 'moment';
import { AddressType } from '../types/address.type';
import { CombinedAddressType } from '../types/combined-address-type';

export class UtilService {
  static toDollars(money: number) {
    return `$${money / 100}`;
  }

  static combineAddressArray(addresses: AddressType[]) {
    let combinedAddresses: CombinedAddressType[] = [];
    for (const address of addresses) {
      const cityAndState = UtilService.combineCityAndState(address.city, address.state);
      combinedAddresses.push({ street: address.street, place: cityAndState, zip: address.zip });
    }
    return combinedAddresses;
  }

  static splitAdressArray(addresses: CombinedAddressType[]) {
    let splittedAddresses: AddressType[] = [];
    for (const address of addresses) {
      const { city, state } = UtilService.splitCityAndState(address.place);
      splittedAddresses.push({ street: address.street, city, state, zip: address.zip });
    }
    return splittedAddresses;
  }

  static combineCityAndState(city: string, state: string) {
    return `${city}, ${state}`;
  }

  static splitCityAndState(cityAndState: string) {
    const splitted = cityAndState.split(', ');
    const city = splitted[0];
    const state = splitted[1];
    return { city, state };
  }

  static getFullName(firstName?: string, middleName?: string, lastName?: string) {
    let fullName = '';
    if (firstName) fullName += firstName + ' ';
    if (middleName) fullName += middleName + ' ';
    if (lastName) fullName += lastName + ' ';

    return fullName.slice(0, fullName.length - 1);
  }

  static unixToReadableDateFormat(date: number) {
    return moment.unix(date).format('MM/DD/YYYY');
  }

  static toReadableDateFormat(date: string) {
    return moment(date).format('MM/DD/YYYY');
  }

  static getFullAddress(street: string, city: string, state: string, zip: string) {
    return `${street}, ${city}, ${state}, ${zip}`;
  }

  static checkFullName(fullname: string | undefined) {
    if (!fullname) return false;
    const splitted = fullname.split(' ');
    if (splitted && splitted.length >= 2 && splitted[0].length >= 2 && splitted[1].length >= 2) return true;
    return false;
  }
}
