import classNames from 'classnames';
import Switch from 'react-switch';
import { Loader } from '../../../components/utils/Loader';
import { UtilService } from '../../../services/Util.service';

type PropsType = {
  regularPrice: number;
  nextInvoicePrice?: number;
  description: string;
  onSwitch: (checked: boolean) => void;
  isAnnually: boolean;
  loading?: boolean;
};

export const PlanPreview = (props: PropsType) => {
  return (
    <div className="plan-preview panel">
      {props.loading && (
        <div className="loader-shade">
          <Loader color="blue" />
        </div>
      )}
      <div className="plan-preview__total-price">
        {UtilService.toDollars(props.regularPrice)}
        {props.isAnnually ? '/year' : '/mo'}
      </div>
      <div className="plan-preview__product">for monitoring {props.description}</div>
      <div className="plan-preview__billing-cycle">
        <span className={classNames({ monthly: true, active: !props.isAnnually })}>Monthly</span>
        <Switch
          onChange={props.onSwitch}
          checked={props.isAnnually}
          onColor="#4197C1"
          onHandleColor="#ffffff"
          handleDiameter={14}
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
        />
        <span className={classNames({ annually: true, active: props.isAnnually })}>Annually (+30% OFF)</span>
      </div>
      <div className="plan-preview__features">
        <ul>
          <li>continuous monitoring on more than 101 data broker websites</li>
          <li>monthly report to your inbox</li>
          <li>premium chat &amp; e-mail support</li>
          <li>unlimited access to useful privacy guides</li>
        </ul>
      </div>
    </div>
  );
};
