import { Form, Formik } from 'formik';
import { useContext } from 'react';
import * as Yup from 'yup';
import { Btn } from '../../components/inputs/Btn';
import { FormControlRow } from '../../components/inputs/form-parts/FormControlRow';
import { FormSubmitRow } from '../../components/inputs/form-parts/FormSubmitRow';
import { TextInput } from '../../components/inputs/TextInput';
import { PopupContext } from '../../components/popable/Popable';
import { setNotification } from '../../redux/actions/notificationAction';
import { updateUser } from '../../redux/actions/user.action';
import { getReduxStoreInstance } from '../../redux/store';
import { UsersApi } from '../../services/api/UsersApi';

type PropsType = {
  userId: string;
  email?: string;
};

export const EmailForm = (props: PropsType) => {
  const popupContext = useContext(PopupContext);
  const store = getReduxStoreInstance();

  return (
    <Formik
      initialValues={{ email: props.email || '' }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Email is mandatory'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        UsersApi.update(props.userId, { email: values.email })
          .then((res) => {
            store.dispatch(updateUser(res.data));
            store.dispatch(setNotification({ message: 'Email successfully updated', type: 'success' }));
            popupContext.closePopup();
          })
          .catch((error) => {
            store.dispatch(setNotification({ message: 'Could not update email', type: 'error' }));
            console.log(error);
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting, errors, isValid }) => (
        <Form>
          <FormControlRow>
            <TextInput label="New email" name="email" type="text" required />
          </FormControlRow>
          <FormSubmitRow>
            <Btn type="submit" color="green" size="medium" label="Save" icon="save" loading={isSubmitting} />
          </FormSubmitRow>
        </Form>
      )}
    </Formik>
  );
};
