import { useEffect, useState } from 'react';
import { DashboardSection } from '../../../components/layout/dashboard-layout/DashboardSection';
import { Table } from '../../../components/layout/table/Table';
import { setNotification } from '../../../redux/actions/notificationAction';
import { getReduxStoreInstance } from '../../../redux/store';
import { AdminFullUserResponse, ProfileWithStats, UsersApi } from '../../../services/api/UsersApi';
import { UtilService } from '../../../services/Util.service';

export const AdminUsersPage = () => {
  const [users, setUsers] = useState<AdminFullUserResponse[]>();
  const store = getReduxStoreInstance();

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const res = await UsersApi.findAll();
        setUsers(res.data);
      } catch (error) {
        store.dispatch(setNotification({ message: 'Could not retrieve all users', type: 'error' }));
        setUsers([]);
        console.log(error);
      }
    };

    fetchAllUsers();
  }, [store]);

  return (
    <DashboardSection title="Users">
      <Table
        loading={users === undefined}
        header={[
          { name: 'id', value: 'ID', width: 18 },
          { name: 'fullName', value: 'Full name', width: 22 },
          { name: 'email', value: 'Email', width: 22 },
          { name: 'activated', value: 'Act.', width: 10 },
          { name: 'numberOfProfiles', value: 'Pro.#', width: 10 },
          { name: 'new', value: 'New', width: 10 },
          { name: 'optinprog', value: 'Opt. in prog.', width: 10 },
          { name: 'removed', value: 'Rem.', width: 10 },
          { name: 'subscriptionStatus', value: 'Sub. status', width: 10 },
        ]}
        widthUnit="rem"
        rowsPerPage={1000}
        content={
          users &&
          users.map((item) => ({
            extra: item.profilesWithStats.length > 0 && (
              <>
                <Table
                  emptyText="No profiles found"
                  header={[
                    { name: 'id', value: 'ID', width: 18 },
                    { name: 'oneRepProfileId', value: 'OR ID', width: 8 },
                    { name: 'fullName', value: 'Full name', width: 22 },
                    { name: 'apStatus', value: 'AP St.', width: 10 },
                    { name: 'orStatus', value: 'OR St.', width: 10 },
                    { name: 'new', value: 'New', width: 10 },
                    { name: 'optinprog', value: 'Opt. in prog.', width: 10 },
                    { name: 'removed', value: 'Rem.', width: 10 },
                  ]}
                  widthUnit="rem"
                  rowsPerPage={1000}
                  hidePagination={true}
                  content={item.profilesWithStats.map((profileWithStats) => ({
                    data: [
                      { name: 'id', value: profileWithStats.profile.id },
                      {
                        name: 'oneRepProfileId',
                        value: profileWithStats.profile.onerepProfileId ? profileWithStats.profile.onerepProfileId.toString() : '-',
                      },
                      {
                        name: 'fullName',
                        value: UtilService.getFullName(
                          profileWithStats.profile.firstName,
                          profileWithStats.profile.middleName,
                          profileWithStats.profile.lastName,
                        ),
                      },
                      { name: 'apStatus', value: profileWithStats.profile.active },
                      { name: 'orStatus', value: profileWithStats.onerepStatus },
                      { name: 'new', value: profileWithStats.new },
                      { name: 'optinprog', value: profileWithStats.optout_in_progresss },
                      { name: 'removed', value: profileWithStats.removed },
                    ],
                  }))}
                />
              </>
            ),

            data: [
              { name: 'id', value: item.user.id },
              { name: 'fullName', value: UtilService.getFullName(item.user.firstName, item.user.middleName, item.user.lastName) },
              { name: 'email', value: item.user.email },
              { name: 'activated', value: `${item.user.activated}` },
              { name: 'numberOfProfiles', value: item.user.profiles.length.toString() },
              { name: 'new', value: sumStats(item.profilesWithStats, 'new').toString() },
              { name: 'optinprog', value: sumStats(item.profilesWithStats, 'optout_in_progresss').toString() },
              { name: 'removed', value: sumStats(item.profilesWithStats, 'removed').toString() },
              { name: 'subscriptionStatus', value: item.user.stripeMeta.subscriptionStatus },
            ],
          }))
        }
      />
    </DashboardSection>
  );
};

const sumStats = (profilesWithStats: ProfileWithStats[], key: string) => {
  let sum = 0;
  for (const single of profilesWithStats) {
    sum += (single as any)[key];
  }
  return sum;
};
