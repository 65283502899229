import { Form, Formik } from 'formik';
import { useContext } from 'react';
import * as Yup from 'yup';
import { Btn } from '../../components/inputs/Btn';
import { DateInput } from '../../components/inputs/DateInput';
import { FormControlRow } from '../../components/inputs/form-parts/FormControlRow';
import { FormSubmitRow } from '../../components/inputs/form-parts/FormSubmitRow';
import { PlaceInput } from '../../components/inputs/PlaceInput';
import { TextInput } from '../../components/inputs/TextInput';
import { PopupContext } from '../../components/popable/Popable';
import { setNotification } from '../../redux/actions/notificationAction';
import { updateUser } from '../../redux/actions/user.action';
import { getReduxStoreInstance } from '../../redux/store';
import { UsersApi } from '../../services/api/UsersApi';
import { UtilService } from '../../services/Util.service';
import {
  FirstNameValidator,
  LastNameValidator,
  BirthdayValidator,
  StreetValidator,
  PlaceValidator,
  ZipValidator,
} from '../../services/validators/YupValidators';

type PropsType = {
  userId: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  birthday?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
};

export const AboutForm = (props: PropsType) => {
  const store = getReduxStoreInstance();
  let combinedPlace = '';
  if (props.city && props.state) combinedPlace = UtilService.combineCityAndState(props.city, props.state);

  const popupContext = useContext(PopupContext);

  return (
    <Formik
      initialValues={{
        firstName: props.firstName || '',
        middleName: props.middleName || '',
        lastName: props.lastName || '',
        birthday: props.birthday || '',
        street: props.street || '',
        place: combinedPlace || '',
        zip: props.zip || '',
      }}
      validationSchema={Yup.object({
        firstName: FirstNameValidator,
        lastName: LastNameValidator,
        birthday: BirthdayValidator,
        street: StreetValidator,
        place: PlaceValidator,
        zip: ZipValidator,
      })}
      onSubmit={(values, { setSubmitting }) => {
        const { city, state } = UtilService.splitCityAndState(values.place);
        const params = { ...values, city, state };

        UsersApi.update(props.userId, params)
          .then((res) => {
            store.dispatch(updateUser(res.data));
            store.dispatch(setNotification({ message: 'User details successfully updated', type: 'success' }));
            popupContext.closePopup();
          })
          .catch((error) => {
            store.dispatch(setNotification({ message: 'Could not update user details', type: 'error' }));
            console.log(error);
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting, errors }) => {
        return (
          <Form>
            <FormControlRow>
              <TextInput label="First Name" name="firstName" type="text" placeholder="e.g. John" autoComplete="given-name" required />
              <TextInput label="Middle Name" name="middleName" type="text" placeholder="e.g. Steve" autoComplete="additional-name" />
              <TextInput label="Last Name" name="lastName" type="text" required placeholder="e.g. Smith" autoComplete="family-name" />
            </FormControlRow>
            <FormControlRow>
              <DateInput label="Date of Birth" name="birthday" type="text" required placeholder="MM/DD/YYYY" limitYears={13} />
            </FormControlRow>
            <FormControlRow>
              <TextInput
                label="Street Address"
                name="street"
                type="text"
                placeholder="e.g. Allen Street 19"
                autoComplete="street-address"
                required
              />
              <PlaceInput label="City" name="place" placeholder="e.g. Boston, MA" autoComplete="off" required />
              <TextInput label="ZIP" name="zip" type="text" placeholder="e.g. 02101" autoComplete="postal-code" required />
            </FormControlRow>
            <FormSubmitRow>
              <Btn type="submit" color="green" size="medium" label="Save" icon="save" loading={isSubmitting} />
            </FormSubmitRow>
          </Form>
        );
      }}
    </Formik>
  );
};
