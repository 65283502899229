import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SITEMAP } from '../../../config/SITEMAP';
import logoSymbol from '../../../img/logo/logo-symbol.svg';
import { getRandomSecurityTip } from '../../../services/getRandomSecurityTip';
import { HubspotService } from '../../../services/HubspotService';

type LoginLayoutProps = {
  title: string;
  description: React.ReactNode;
  afterText?: React.ReactNode;
  children: React.ReactNode;
};

export const LoginLayout = (props: LoginLayoutProps) => {
  const securityTip = getRandomSecurityTip();

  useEffect(() => {
    HubspotService.hideHubspotChat();
  }, []);

  return (
    <div className="login-layout">
      <div className="login-layout__sidebar">
        <Link to={SITEMAP.HOME}>
          <img className="logo" src={logoSymbol} alt="Allen Privacy" />
        </Link>
        <div className="login-layout__sidebar__content">
          <h1 className="title">{props.title}</h1>
          <p className="description">{props.description}</p>
          <div className="form">{props.children}</div>
          {props.afterText && <p className="after-text">{props.afterText}</p>}
        </div>
      </div>
      <div className="login-layout__tips">
        <div className="login-tips-curtain"></div>
        <div className="login-tip">
          <h2>Did you know?</h2>
          <p>{securityTip.description}</p>
        </div>
        <div className="login-tip-background" style={{ backgroundImage: `url('${securityTip.image}')` }}></div>
      </div>
    </div>
  );
};
