import { Helmet } from 'react-helmet';
import { SmoothAnimate } from '../../../components/smooth-animate/SmoothAnimate';
import { Loader } from '../../../components/utils/Loader';
import { SeoService } from '../../../services/SeoService';

type PropsType = {
  isVisible: boolean;
};

export const Scanning = (props: PropsType) => {
  return (
    <SmoothAnimate isVisible={props.isVisible}>
      <Helmet>
        <title>{SeoService.getPageTitle('Scanning...')}</title>
      </Helmet>
      <Loader id="scan-loader" color="blue" />
      <h1 className="panel__title">Hang on, we’re on it!</h1>
      <p className="panel__description">
        We​ ​are​ ​analyzing​ ​the​ ​most​ ​popular​ data broker websites​ ​to​ ​see​ ​whether​ ​they​ ​have​ ​posted​ ​your​ ​name,​ ​age,​
        ​current​ ​and​ ​previous addresses,​ ​phone​ ​numbers,​ ​information​ ​about​ ​your​ ​relatives​ ​and​ ​much​ ​more.
      </p>
    </SmoothAnimate>
  );
};
