import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { DashboardContext } from '../../../components/layout/dashboard-layout/DashboardLayout';
import { DashboardSection } from '../../../components/layout/dashboard-layout/DashboardSection';
import { AppState } from '../../../redux/store';
import { SeoService } from '../../../services/SeoService';
import { ResourceItem } from './components/ResourceItem';

export const ResourcesPage = () => {
  const user = useSelector((state: AppState) => state.user);
  const dashboardContext = useContext(DashboardContext);

  useState(() => {
    dashboardContext.setDashboardLocation('Resources');
  });

  const isActive = user.activated && user.stripeMeta && user.stripeMeta.subscriptionStatus === 'active';

  return (
    <DashboardSection title="Resources">
      <Helmet>
        <title>{SeoService.getPageTitle('Resources')}</title>
      </Helmet>

      <p>Workbooks and resources that you might find useful.</p>
      <ResourceItem
        type="pdf"
        title="Failed Oversight and the Rise of Insider Threats"
        description="Last updated: 08/2020"
        url="https://app.allenprivacy.com/resources/Allen_Forensics_Failed_Oversight_and_Insider_Threats.pdf"
        locked={!isActive}
      />
      <ResourceItem
        type="pdf"
        title="Know Your Cyber Enemy"
        description="Last updated: 08/2020"
        url="https://app.allenprivacy.com/resources/Allen_Forensics_Know_Your_Cyber_Enemy.pdf"
        locked={!isActive}
      />
      <ResourceItem
        type="pdf"
        title="Third Party Fraud - Everything you need to know"
        description="Last updated: 08/2020"
        url="https://app.allenprivacy.com/resources/Allen_Forensics_Third_Party_Fraud-whitepaper.pdf"
        locked={!isActive}
      />
    </DashboardSection>
  );
};
