import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { getReduxStoreInstance } from '../redux/store';
import { PrivateRoute } from './PrivateRoute';
import { AuthService } from '../services/Auth.service';
import { PublicRoute } from './PublicRoute';
import { SITEMAP } from '../config/SITEMAP';
import { DashboardRouter } from '../pages/dashboard/DashboardRouter';
import { CSSTransition } from 'react-transition-group';
import { CustomizePlanPage } from '../pages/customize-plan/CustomizePlanPage';
import { CheckoutPage } from '../pages/checkout/CheckoutPage';
import { LoginPage } from '../pages/login/LoginPage';
import { RegisterPage } from '../pages/register/RegisterPage';
import { ScanPage } from '../pages/scan/ScanPage';
import { NotFoundPage } from '../pages/not-found/NotFoundPage';
import { ActivateUserPage } from '../pages/activate-user/ActivateUserPage';
import { ForgotPasswordPage } from '../pages/forgot-password/ForgotPasswordPage';
import { ResetPasswordPage } from '../pages/reset-password/ResetPasswordPage';
import { NotificationsWrapper } from './NotificationsWrapper';
import { Loader } from '../components/utils/Loader';

// Configure store
const store = getReduxStoreInstance();

export const AppRouter = () => {
  const [isLoading, setIsLoading] = useState(true);
  const nodeRef = React.useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      await AuthService.autoLogin();
      setIsLoading(false);
    };

    if (AuthService.getAccessToken()) {
      setIsLoading(true);
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, []);

  return (
    <Provider store={store}>
      <NotificationsWrapper>
        <CSSTransition nodeRef={nodeRef} in={isLoading} timeout={200} classNames="full-page-loader" unmountOnExit={true}>
          <div ref={nodeRef} className="full-page-loader">
            <Loader color="blue" width="thin" />
          </div>
        </CSSTransition>
        {!isLoading && (
          <Router>
            <ScrollToTop />
            <Switch>
              <PrivateRoute path="/" exact>
                <Redirect to={SITEMAP.DASHBOARD} />
              </PrivateRoute>
              <PrivateRoute path={SITEMAP.DASHBOARD} component={DashboardRouter} />
              <PrivateRoute path={SITEMAP.CUSTOMIZE_PLAN} component={CustomizePlanPage} />
              <PrivateRoute path={SITEMAP.CHECKOUT} component={CheckoutPage} />
              <PublicRoute path={SITEMAP.REGISTER} component={RegisterPage} />
              <PublicRoute path={SITEMAP.LOGIN} component={LoginPage} />
              <PublicRoute path="/forgot-password" component={ForgotPasswordPage} />
              <PublicRoute path="/reset-password/:userId/:token" component={ResetPasswordPage} />
              <Route path="/activate-account/:userId/:code" component={ActivateUserPage} />
              <PublicRoute path={SITEMAP.SCAN} component={ScanPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </Router>
        )}
      </NotificationsWrapper>
    </Provider>
  );
};

const ScrollToTop = () => {
  const history = useHistory();
  const currentLocation = useLocation();

  useEffect(() => {
    const unlisten = history.listen((newLocation) => {
      if (currentLocation.pathname !== newLocation.pathname) window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [currentLocation.pathname, history]);

  return null;
};
