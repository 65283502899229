import classNames from 'classnames';
import { useField, FieldHookConfig } from 'formik';

export const CheckboxInput = (props: FieldHookConfig<boolean> & { label: React.ReactNode }) => {
  const [field, meta, helper] = useField(props);
  const showError = meta.error;

  return (
    <div className="checkbox-input-wrapper">
      <label>
        <input type="checkbox" name="toggle" checked={!!field.value} onChange={() => helper.setValue(!field.value)} />
        <span className="checkmark-box">
          <span className="checkmark icon-checkmark"></span>
        </span>
        <span className={classNames({ label: true, error: showError })}>{props.label}</span>
      </label>
    </div>
  );
};
