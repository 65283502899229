import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Loader } from '../Loader';

export const FullDivLoaderWrapper = ({ loading }: { loading: boolean }) => {
  const childRef = React.useRef(null);

  return (
    <CSSTransition in={loading} findDOMNode={() => childRef.current} timeout={200} classNames="full-div-loader" unmountOnExit={true}>
      <div ref={childRef} className="full-div-loader">
        <Loader color="blue" />
      </div>
    </CSSTransition>
  );
};
