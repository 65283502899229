import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { ProfilesList } from '../../components/app-specific/ProfilesList';
import { Btn } from '../../components/inputs/Btn';
import { SimpleContent } from '../../components/layout/simple-content/SimpleContent';
import { SimpleLayout } from '../../components/layout/simple-layout/SimpleLayout';
import { Help } from '../../components/utils/help/Help';
import { SITEMAP } from '../../config/SITEMAP';
import { AppState } from '../../redux/store';
import { PricePreviewResponse, SubscriptionsApi } from '../../services/api/SubscriptionsApi';
import { SeoService } from '../../services/SeoService';
import { NotFoundPage } from '../not-found/NotFoundPage';
import { PlanPreview } from './components/PlanPreview';

export const CustomizePlanPage = () => {
  const [isAnnually, setAnnual] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [currentPrice, setCurrentPrice] = useState<PricePreviewResponse>({
    regularPrice: 0,
    nextInvoicePrice: 0,
    stripePriceId: '',
    description: '0 profiles',
  });
  const user = useSelector((state: AppState) => state.user);
  const stripeMeta = user.stripeMeta;
  const profiles = user.profiles;

  useEffect(() => {
    const refreshPricePreview = async () => {
      SubscriptionsApi.getPricePreview(user.id, { numberOfProfiles: profiles.length, annual: isAnnually })
        .then((res) => {
          setCurrentPrice(res.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    };

    setLoading(true);
    refreshPricePreview();
  }, [profiles, isAnnually, user.id]);

  const history = useHistory();

  const handleSwitchChange = (checked: boolean) => {
    setAnnual(checked);
  };

  if (stripeMeta.paymentIntentStatus === 'requires_payment_method') return <Redirect to={SITEMAP.ACCOUNT} />;
  if (stripeMeta.paymentIntentStatus === 'requires_action') return <Redirect to={SITEMAP.ACCOUNT} />;
  if (stripeMeta && stripeMeta.subscriptionId) return <NotFoundPage />;

  return (
    <SimpleLayout>
      <Helmet>
        <title>{SeoService.getPageTitle('Customize Plan')}</title>
      </Helmet>
      <SimpleContent
        title="Customize your plan"
        description="Please fill in all the profiles you want us to monitor, enter information we should look up for while searching the Internet. Subscribe today, cancel anytime!"
      >
        <div className="customize-plan">
          <div className="customize-plan__profiles">
            <div className="customize-section-title">
              <h2>Your profiles</h2>
              <Help
                align="right"
                message="Profiles are personal identities that we monitor. If you’d like to secure your family members or friends too, add their profiles and save 10%. If you choose to pay annually, you get additional 25% off the regular price."
              />
            </div>
            <ProfilesList allowAdd={true} />
          </div>
          {user.profiles.length > 0 && (
            <div className="customize-plan__checkout">
              <div className="customize-section-title">
                <h2>Your plan</h2>
              </div>
              <PlanPreview
                loading={isLoading}
                regularPrice={currentPrice.regularPrice}
                onSwitch={handleSwitchChange}
                isAnnually={isAnnually}
                description={currentPrice.description}
              />
              <Btn
                label="Proceed to payment"
                hasArrow={true}
                color="blue"
                onClick={(e) => history.push({ pathname: SITEMAP.CHECKOUT, state: { isAnnually: isAnnually } })}
                disabled={isLoading}
              />
              {/* <div className="notes">
              <a href="#">Ask me later</a>
            </div> */}
            </div>
          )}
        </div>
      </SimpleContent>
    </SimpleLayout>
  );
};
