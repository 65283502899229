import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppState } from '../redux/store';
import { AuthService } from '../services/Auth.service';

export const PrivateRoute = (props: RouteProps) => {
  const user = useSelector((state: AppState) => state.user);

  if (AuthService.isValidToken() && user !== null && Object.keys(user).length !== 0) return <Route {...props} />;

  return <Redirect to="/login" />;
};
