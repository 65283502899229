import { useHistory } from 'react-router-dom';
import { Btn } from '../../components/inputs/Btn';
import { SimpleLayout } from '../../components/layout/simple-layout/SimpleLayout';
import { SITEMAP } from '../../config/SITEMAP';
import notFoundImage from '../../img/404/404.svg';

export const NotFoundPage = () => {
  const history = useHistory();

  return (
    <SimpleLayout id="simple-central-page" className="not-found-page">
      <img id="not-found-illustration" src={notFoundImage} alt="Not found" />
      <h1>
        Someone stole this page.
        <br />
        Don't let it happen to your identity.
      </h1>
      <Btn color="blue" label="Check my privacy" onClick={() => history.push(SITEMAP.SCAN)} />
    </SimpleLayout>
  );
};
