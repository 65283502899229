import { useSelector } from 'react-redux';
import { Btn } from '../inputs/Btn';
import { DashboardPanel } from '../layout/dashboard-layout/DashboardPanel';
import { Popable } from '../popable/Popable';
import { ProfileForm } from '../../forms/profile/ProfileForm';
import { AppState } from '../../redux/store';
import { ProfileResponse } from '../../services/api/ProfilesApi';
import { UtilService } from '../../services/Util.service';

type PropsType = {
  profile: ProfileResponse;
  role: 'Account owner' | 'Member';
  onUpdateResult?: (profile: ProfileResponse) => void;
  onDeleteResult?: (profileId: string) => void;
};

export const ProfileCard = (props: PropsType) => {
  const user = useSelector((state: AppState) => state.user);

  return (
    <DashboardPanel className="profile-panel">
      <div className="profile-panel__info">
        <h3>{UtilService.getFullName(props.profile.firstName, props.profile.middleName, props.profile.lastName)}</h3>
        <p>{props.role}</p>
      </div>
      <div className="profile-panel__btns">
        <Popable id={props.profile.id} title="Edit profile" trigger={<Btn label="Edit" icon="edit" size="medium" />}>
          <ProfileForm
            userId={user.id}
            profileId={props.profile.id}
            firstName={props.profile.firstName}
            middleName={props.profile.middleName}
            lastName={props.profile.lastName}
            birthday={props.profile.birthday}
            emails={props.profile.emails}
            phones={props.profile.phones}
            addresses={props.profile.addresses}
            onUpdateResult={(profile: ProfileResponse) => {
              if (props.onUpdateResult) props.onUpdateResult(profile);
            }}
            onDeleteResult={props.onDeleteResult}
          />
        </Popable>
      </div>
    </DashboardPanel>
  );
};
