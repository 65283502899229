import loaderBlue from '../../img/loader/loader-blue.svg';
import loaderBlueThin from '../../img/loader/loader-blue-thin.svg';
import loaderWhite from '../../img/loader/loader-white.svg';

type LoaderProps = {
  id?: string;
  color: 'blue' | 'white';
  width?: 'normal' | 'thin';
};

export const Loader = (props: LoaderProps) => {
  let loader;

  if (props.color === 'blue') {
    if (props.width === 'thin') loader = loaderBlueThin;
    else loader = loaderBlue;
  } else if (props.color === 'white') {
    if (props.width === 'thin') loader = loaderBlueThin;
    else loader = loaderWhite;
  }

  return (
    <div id={props.id ?? ''} className="loader">
      <img src={loader} alt="Loading..." />
    </div>
  );
};
