export const SITEMAP = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  PROFILE: (id: string) => `/dashboard/profiles/${id}`,
  RESOURCES: '/dashboard/resources',
  ACCOUNT: '/dashboard/settings/account',
  MANAGE_PROFILES: '/dashboard/settings/manage-profiles',
  ADMIN_USERS: '/dashboard/admin/users',
  ADMIN_STATS: '/dashboard/admin/stats',
  REGISTER: '/register',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  SCAN: '/scan',
  CHOOSE_PLAN: '/choose-plan',
  CUSTOMIZE_PLAN: '/customize-plan',
  CHECKOUT: '/checkout',
  TERMS_OF_USE: 'https://allenprivacy.com/terms-of-use',
  PRIVACY_POLICY: 'https://allenprivacy.com/privacy-policy',
};
