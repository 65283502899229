import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

export const PopupContext = React.createContext({ closePopup: () => {} });

type PropsType = {
  id: string;
  title: string;
  trigger: React.ReactNode;
  children: React.ReactNode; // Popup content
};

export const CloseAnyPopup = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  history.push(pathname);
};

export const Popable = (props: PropsType) => {
  const nodeRef = React.useRef(null);

  const { pathname, hash, state } = useLocation();
  const history = useHistory();

  const isOpen = hash === `#${props.id}`;

  const handleOpen = () => {
    history.push({ pathname, hash: props.id, state });
  };

  const handleClose = () => {
    history.push({ pathname, state });
  };

  return (
    <PopupContext.Provider value={{ closePopup: handleClose }}>
      <CSSTransition nodeRef={nodeRef} in={isOpen} timeout={200} classNames="popup-wrapper" unmountOnExit>
        <div ref={nodeRef} className="popup-wrapper">
          <div className="popup">
            <div className="popup__header">
              <h3 className="popup__header__title">{props.title}</h3>
              <button className="popup__header__close" onClick={(e) => handleClose()}>
                Close X
              </button>
            </div>
            <div className="popup__content">{props.children}</div>
          </div>
          <div className="popup-shade" onClick={(e) => handleClose()}></div>
        </div>
      </CSSTransition>
      <div onClick={(e) => handleOpen()}>{props.trigger}</div>
    </PopupContext.Provider>
  );
};
