import { SmoothAnimate } from '../../../components/smooth-animate/SmoothAnimate';
import attentionImage from '../../../img/icons/attention.svg';
import { Table } from '../../../components/layout/table/Table';
import { Btn } from '../../../components/inputs/Btn';
import { Link, useHistory } from 'react-router-dom';
import { SITEMAP } from '../../../config/SITEMAP';
import { Helmet } from 'react-helmet';
import { SeoService } from '../../../services/SeoService';

type PropsType = {
  isVisible: boolean;
  scanResults: any;
};

export const ScanResults = (props: PropsType) => {
  const history = useHistory();

  return (
    <SmoothAnimate isVisible={props.isVisible}>
      <Helmet>
        <title>{SeoService.getPageTitle('Scan Results')}</title>
      </Helmet>
      <div className="simple-content">
        <img className="simple-content__icon" src={attentionImage} alt="" />
        <h1 className="simple-content__title">We have the bad news</h1>
        <p className="simple-content__description">
          We found {props.scanResults.length} records of your profile on data broker websites. <Link to={SITEMAP.REGISTER}>Click here</Link>{' '}
          to proceed with information removal.
        </p>
        <Table
          header={[
            { name: 'dataBroker', value: 'Data Broker', width: 25 },
            { name: 'profile', value: 'Profile', width: 20 },
            { name: 'location', value: 'Location', width: 25 },
            { name: 'phone', value: 'Phone', width: 15 },
          ]}
          rowsPerPage={10}
          content={
            props.scanResults &&
            props.scanResults.map((scanResult: any) => ({
              url: scanResult.url,
              data: [
                { name: 'dataBroker', value: scanResult.dataBroker },
                { name: 'profile', value: scanResult.profile },
                { name: 'location', value: scanResult.location },
                { name: 'phone', value: scanResult.phone },
              ],
            }))
          }
        />
        <div className="scan-results-controls">
          <Btn label="Remove my private info" color="red" hasArrow={true} onClick={() => history.push(SITEMAP.REGISTER)} />
        </div>
      </div>
    </SmoothAnimate>
  );
};
