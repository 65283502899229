type PropsType = {
  label: string;
  onClick?: (e: React.MouseEvent) => void;
};

export const FormAddBtn = (props: PropsType) => {
  return (
    <button onClick={props.onClick} className="form-add-btn" type="button">
      {props.label}
    </button>
  );
};
