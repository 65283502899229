import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Btn } from '../../components/inputs/Btn';
import { FormControlRow } from '../../components/inputs/form-parts/FormControlRow';
import { FormSubmitRow } from '../../components/inputs/form-parts/FormSubmitRow';
import { PasswordCheckup, validatePassword } from '../../components/inputs/PasswordCheckup';
import { TextInput } from '../../components/inputs/TextInput';
import { AuthApi } from '../../services/api/AuthApi';
import { PopupContext } from '../../components/popable/Popable';
import { useContext, useState } from 'react';
import { InfoRow } from '../../components/inputs/form-parts/InfoRow';
import { SITEMAP } from '../../config/SITEMAP';
import { useHistory } from 'react-router-dom';
import { getReduxStoreInstance } from '../../redux/store';
import { setNotification } from '../../redux/actions/notificationAction';

type PropsType = {
  userId: string;
  reset?: boolean;
  token?: string;
};

export const PasswordForm = (props: PropsType) => {
  const [errorMsg, setErrorMsg] = useState('');
  const popupContext = useContext(PopupContext);
  const store = getReduxStoreInstance();
  const history = useHistory();

  return (
    <Formik
      initialErrors={{ password: '0123' }}
      initialValues={{ oldPassword: '', password: '', passwordConfirmation: '' }}
      validate={validatePassword}
      validationSchema={Yup.object({
        oldPassword: Yup.string().test('old-password', 'Old password is required', (value) => {
          if (props.reset) return true;
          if (value && value.length > 0) return true;
          return false;
        }),
      })}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        if (props.reset && props.token) {
          /**
           * User from the "Forgot Password" page
           */
          AuthApi.resetPassword(props.userId, props.token, values)
            .then((res) => {
              setSubmitting(false);
              history.push({
                pathname: SITEMAP.LOGIN,
                state: { fromResetForm: true },
              });
            })
            .catch((error) => {
              setErrorMsg('There was an error');
              setSubmitting(false);
            });
        } else {
          /**
           * User changing password from dashboard menu
           */
          AuthApi.changePassword(props.userId, values)
            .then((res) => {
              popupContext.closePopup();
              store.dispatch(setNotification({ message: 'Password successfully updated', type: 'success' }));
              setSubmitting(false);
            })
            .catch((error) => {
              store.dispatch(setNotification({ message: 'Could not update password', type: 'error' }));
              if (error.statusCode === 401) {
                setErrors({ oldPassword: 'Invalid password' });
              } else {
                setErrorMsg('There was an error');
              }
              setSubmitting(false);
            });
        }
      }}
    >
      {({ isSubmitting, errors, isValid }) => (
        <Form>
          {!props.reset && (
            <FormControlRow>
              <TextInput label="Old password" name="oldPassword" type="password" autoComplete="current-password" required />
            </FormControlRow>
          )}
          <FormControlRow>
            <TextInput label="New password" name="password" type="password" autoComplete="new-password" hideErrorLabel={true} required />
          </FormControlRow>
          <FormControlRow>
            <TextInput
              label="Repeat new password"
              name="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              hideErrorLabel={true}
              required
            />
          </FormControlRow>
          <PasswordCheckup passwordErrors={errors.password} />
          {errorMsg && <InfoRow message={errorMsg} type="error" />}
          <FormSubmitRow>
            {props.reset ? (
              <Btn type="submit" label="Reset password" hasArrow={true} loading={isSubmitting} />
            ) : (
              <Btn type="submit" size="medium" color="green" label="Change password" loading={isSubmitting} />
            )}
          </FormSubmitRow>
        </Form>
      )}
    </Formik>
  );
};
