import { DashboardSection } from '../../../../../components/layout/dashboard-layout/DashboardSection';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../redux/store';
import { UtilService } from '../../../../../services/Util.service';
import { ReceiptResponse, SubscriptionsApi } from '../../../../../services/api/SubscriptionsApi';
import { useEffect, useState } from 'react';
import { Table } from '../../../../../components/layout/table/Table';

export const Receipts = () => {
  const user = useSelector((state: AppState) => state.user);
  const [invoices, setInvoices] = useState<ReceiptResponse[]>();

  useEffect(() => {
    const fetchInvoices = async () => {
      const { data } = await SubscriptionsApi.getAllInvoices(user.id);
      setInvoices(data);
    };

    fetchInvoices();
  }, [user.id]);

  return (
    <DashboardSection title="Receipts">
      <Table
        loading={invoices === undefined}
        header={[
          { name: 'id', value: 'ID', width: 25 },
          { name: 'amount', value: 'Amount paid', width: 20 },
          { name: 'date', value: 'Date', width: 10 },
          { name: 'status', value: 'Status', width: 10 },
        ]}
        rowsPerPage={10}
        content={
          invoices &&
          invoices.map((item) => ({
            url: item.url,
            data: [
              { name: 'id', value: item.id },
              { name: 'amount', value: UtilService.toDollars(item.amount) },
              { name: 'date', value: UtilService.unixToReadableDateFormat(item.created) },
              { name: 'status', value: item.status },
            ],
          }))
        }
      />
    </DashboardSection>
  );
};
