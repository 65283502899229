import { AuthService } from '../Auth.service';
import { get, post } from './config';

type CreateScannerRequest = {
  fullName: string;
  city: string;
  recaptchaToken: string;
};

type ScannerResponse = {
  scannerId: string;
};

export type ScanResultsResponse = {
  dataBroker: string;
  profile: string;
  location: string;
  phone: string;
  url: string;
};

export type ProfilesStatsResponse = {
  created: number;
  deleted: number;
  activated: number;
  reactivated: number;
  deactivated: number;
  total_active: number;
  total_inactive: number;
  total: number;
};

export type ScansStatsResponse = {
  monitoring: number;
  manual: number;
  initial: number;
  total: number;
};

export type CurrentScanResultsStatsResponse = {
  new: number;
  optout_in_progresss: number;
  waiting_for_verification: number;
  removed: number;
  total: number;
};
export type HistoricalScanResultsStatsResponse = {
  created: number;
  submitted_for_optout: number;
  removed: number;
  filtered: number;
};

export type CurrentRecordsStatsResponse = {
  new: number;
  optout_in_progresss: number;
  waiting_for_verification: number;
  removed: number;
  total: number;
};
export type HistoricalRecordsStatsResponse = {
  created: number;
  submitted_for_optout: number;
  removed: number;
  filtered: number;
};

export class ScannerApi {
  static createScanner = async (params: CreateScannerRequest) => await post<ScannerResponse>('/scanner', params);

  static getScanResults = async (id: string) => await get<ScanResultsResponse[]>('/scanner/' + id);

  static getScansStats = async (from: string, to: string) =>
    await get<ScansStatsResponse>(`/scanner/stats/scans?from=${from}&to=${to}`, AuthService.getAuthHeader());

  static getProfilesStats = async (from: string, to: string) =>
    await get<ProfilesStatsResponse>(`/scanner/stats/profiles?from=${from}&to=${to}`, AuthService.getAuthHeader());

  static getCurrentScanResultsStats = async () =>
    await get<CurrentScanResultsStatsResponse>('/scanner/stats/scan-results/current', AuthService.getAuthHeader());

  static getHistoricalScanResultsStats = async (from: string, to: string) =>
    await get<HistoricalScanResultsStatsResponse>(`/scanner/stats/scan-results/history?from=${from}&to=${to}`, AuthService.getAuthHeader());

  static getCurrentRecordsStats = async () =>
    await get<CurrentRecordsStatsResponse>('/scanner/stats/records/current', AuthService.getAuthHeader());

  static getHistoricalRecordsStats = async (from: string, to: string) =>
    await get<HistoricalRecordsStatsResponse>(`/scanner/stats/records/history?from=${from}&to=${to}`, AuthService.getAuthHeader());
}
