import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SITEMAP } from '../../../config/SITEMAP';
import logoSymbol from '../../../img/logo/logo-symbol.svg';
import { HubspotService } from '../../../services/HubspotService';

type Props = {
  id?: string;
  children: React.ReactNode;
  className?: string;
};

export const SimpleLayout = (props: Props) => {
  useEffect(() => {
    HubspotService.hideHubspotChat();
  });

  return (
    <div id={props.id ?? ''} className={`simple-layout ${props.className}`}>
      <header className="simple-layout__header">
        <div className="container">
          <Link to={SITEMAP.HOME}>
            <img src={logoSymbol} alt="Allen Privacy Symbol" />
          </Link>
          <div className="header-questions">
            <span className="header-questions__question">Have any questions?</span>
            <ChatBtn />
          </div>
        </div>
      </header>
      <main className="simple-layout__main">
        <div className="container">{props.children}</div>
      </main>
      <footer className="simple-layout__footer">
        <div className="container">
          <p>
            Copyright &copy; Allen Forensics Inc. | <a href="https://allenprivacy.com/terms-of-use">Terms of Use</a> |{' '}
            <a href="https://allenprivacy.com/privacy-policy">Privacy Policy</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

const ChatBtn = () => {
  return (
    <button
      className="btn--chat"
      onClick={() => {
        HubspotService.showHubspotChat();
      }}
    >
      <span>Chat with us</span>
    </button>
  );
};
