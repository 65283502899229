import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { LoginLayout } from '../../components/layout/login-layout/LoginLayout';
import { PasswordForm } from '../../forms/password/PasswordForm';
import { SeoService } from '../../services/SeoService';

export const ResetPasswordPage = () => {
  const { userId, token } = useParams<{ userId: string; token: string }>();

  return (
    <LoginLayout title="Set a new password" description={`Please choose your new password.`}>
      <Helmet>
        <title>{SeoService.getPageTitle('Reset Password')}</title>
      </Helmet>
      <PasswordForm userId={userId} token={token} reset={true} />
    </LoginLayout>
  );
};
