import classNames from 'classnames';

type WrapperProps = {
  id?: string;
  children: React.ReactNode;
};

export const TextInputWrapper = (props: WrapperProps) => (
  <div className={classNames({ 'text-input-wrapper': true, [props.id || '']: true })}>{props.children}</div>
);
