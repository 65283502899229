import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Btn } from '../../components/inputs/Btn';
import { FormControlRow } from '../../components/inputs/form-parts/FormControlRow';
import { TextInputError } from '../../components/inputs/text-input-parts/TextInputError';
import { TextInputLabel } from '../../components/inputs/text-input-parts/TextInputLabel';
import { TextInputWrapper } from '../../components/inputs/text-input-parts/TextInputWrapper';
import { PopupContext } from '../../components/popable/Popable';
import { setNotification } from '../../redux/actions/notificationAction';
import { setStripeMeta } from '../../redux/actions/user.action';
import { AppState, getReduxStoreInstance } from '../../redux/store';
import { SubscriptionsApi } from '../../services/api/SubscriptionsApi';

type PropsType = {
  replace?: boolean;
  loading?: boolean;
  onCreatePaymentMethod?: (paymentMethodId: string) => void;
};

export const CreditCardForm = (props: PropsType) => {
  const stripe = useStripe();
  const user = useSelector((state: AppState) => state.user);
  const store = getReduxStoreInstance();
  const elements = useElements();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const popupContext = useContext(PopupContext);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) return;
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) return;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setError(error.message || 'Please check your card information');
    } else {
      const paymentMethodId = paymentMethod?.id;
      if (!paymentMethodId) {
        setError('Payment method ID does not exist');
        return;
      }

      if (props.onCreatePaymentMethod) props.onCreatePaymentMethod(paymentMethodId);

      if (props.replace) {
        try {
          const res = await SubscriptionsApi.updatePaymentMethod(user.id, { paymentMethodId });
          store.dispatch(setStripeMeta(res.data));
          store.dispatch(setNotification({ message: 'Payment method successfully updated', type: 'success' }));
        } catch (error) {
          store.dispatch(setNotification({ message: 'Could not update payment method', type: 'error' }));
        }
      }
      popupContext.closePopup();
    }
    setLoading(false);
  };

  return (
    <form className="card-form" onSubmit={handleSubmit}>
      <FormControlRow>
        <TextInputWrapper>
          <TextInputLabel label="Card information" required={true} />
          <CardElement />
          <TextInputError showError={!!error} error={error} />
        </TextInputWrapper>
        {!props.replace ? (
          <Btn
            type="submit"
            label="Purchase"
            color="green"
            icon="purchase"
            size="medium"
            disabled={!stripe}
            loading={isLoading || props.loading}
          />
        ) : (
          <Btn type="submit" label="Replace" color="blue" size="medium" disabled={!stripe} loading={isLoading || props.loading} />
        )}
      </FormControlRow>
    </form>
  );
};
