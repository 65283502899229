type PropsType = {
  title: string;
  children: React.ReactNode;
  className?: string;
};
export const DashboardSection = (props: PropsType) => {
  return (
    <div className={`dashboard-section ${props.className}`}>
      <h2>{props.title}</h2>
      {props.children}
    </div>
  );
};
