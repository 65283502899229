import classNames from 'classnames';

export enum PASSWORD_ERRORS {
  MIN_8_CHARS = '0',
  LETTERS_AND_NUMBERS = '1',
  SPECIAL_CHAR = '2',
  PASSWORD_MATCH = '3',
}

/**
 * Function checks password and password confirmation values and returns appropriate errors codes in string format.
 * @param values Formik form values containing password and passwordConfirmation values.
 */
export const validatePassword = (values: { password: string; passwordConfirmation: string }) => {
  const password = values.password;
  const passwordConfirmation = values.passwordConfirmation;
  let passwordErrors = '';
  let passwordsNotEqual = false;
  const specialChars = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

  if (!password) passwordErrors += PASSWORD_ERRORS.MIN_8_CHARS + PASSWORD_ERRORS.PASSWORD_MATCH;
  if (password.length < 8 || password.length > 20) passwordErrors += PASSWORD_ERRORS.MIN_8_CHARS;
  if (!/\d/.test(password)) passwordErrors += PASSWORD_ERRORS.LETTERS_AND_NUMBERS;
  if (!/[a-zA-Z]/.test(password)) passwordErrors += PASSWORD_ERRORS.LETTERS_AND_NUMBERS;
  if (!specialChars.test(password)) passwordErrors += PASSWORD_ERRORS.SPECIAL_CHAR;
  if (password !== passwordConfirmation) {
    passwordErrors += PASSWORD_ERRORS.PASSWORD_MATCH;
    passwordsNotEqual = true;
  }

  let errors: any = {};
  // If there are some errors
  if (passwordErrors.length > 0) {
    errors.password = passwordErrors;
    if (passwordsNotEqual || !password) errors.passwordConfirmation = true;
  }

  return errors;
};

type PasswordCheckupProps = {
  passwordErrors: string | undefined;
};

export const PasswordCheckup = ({ passwordErrors }: PasswordCheckupProps) => {
  /**
   * Function checks if certain security issue is contained in password errors.
   * @param lookingFor Password security check we are looking for
   */
  const isPassItemValid = (lookingFor: string) => {
    const foundIndex = passwordErrors?.indexOf(lookingFor);

    // Item found in error list
    if (foundIndex !== undefined && foundIndex >= 0) return false;

    // Item was not found in error list
    return true;
  };

  return (
    <div className="password-checkup">
      <span className={classNames({ valid: isPassItemValid(PASSWORD_ERRORS.MIN_8_CHARS) })}>Password has between 8 and 20 characters</span>
      <span className={classNames({ valid: isPassItemValid(PASSWORD_ERRORS.LETTERS_AND_NUMBERS) })}>A mixture of letters and numbers</span>
      <span className={classNames({ valid: isPassItemValid(PASSWORD_ERRORS.SPECIAL_CHAR) })}>
        The inclusion of at least one special character
      </span>
      <span className={classNames({ valid: isPassItemValid(PASSWORD_ERRORS.PASSWORD_MATCH) })}>Passwords match</span>
    </div>
  );
};
