import React, { useEffect, useState } from 'react';
import { SimpleLayout } from '../../components/layout/simple-layout/SimpleLayout';
import { Welcome } from './components/Welcome';
import { Scanning } from './components/Scanning';
import { ScanResults } from './components/ScanResults';
import { ScanResultsResponse, ScannerApi } from '../../services/api/ScannerApi';
import { SmoothAnimate } from '../../components/smooth-animate/SmoothAnimate';
import { TryAgain } from './components/TryAgain';
import { NotFound } from './components/NotFound';
import { Helmet } from 'react-helmet';
import { SeoService } from '../../services/SeoService';
import { ScannerService } from '../../services/ScannerService';
import { getReduxStoreInstance } from '../../redux/store';
import { setNotification } from '../../redux/actions/notificationAction';
import { FullPageLoader } from '../../components/utils/full-page-loader/FullPageLoader';

export const ScanPage = () => {
  const [inProgress, setInProgress] = useState(false);
  const [scanResults, setScanResults] = useState<ScanResultsResponse[]>([]);
  const [isDataReceived, setIsDataReceived] = useState(false);
  const [noOfTries, setNoOfTries] = useState(0);
  const [fullName, setFullName] = useState('');
  const [place, setPlace] = useState('');
  const store = getReduxStoreInstance();

  let timeoutVar: NodeJS.Timeout;
  let counter = 0;

  useEffect(() => {
    // Check if scanner ID already exists
    const getScanResults = async (scannerId: string) => {
      const res = await ScannerApi.getScanResults(scannerId);
      setScanResults(res.data);
      setIsDataReceived(true);
    };

    const scannerId = ScannerService.getScannerId();
    if (scannerId) getScanResults(scannerId);
  }, []);

  const handleSubmitSearch = async (fullName: string, city: string, recaptchaToken: string) => {
    setInProgress(true);
    setFullName(fullName);
    setPlace(city);
    setNoOfTries(noOfTries + 1);

    try {
      const res = await ScannerApi.createScanner({ fullName, city, recaptchaToken });
      const scannerId = res.data.scannerId;

      timeoutVar = setInterval(async () => {
        try {
          const res = await ScannerApi.getScanResults(scannerId);
          setScanResults(res.data);
          setIsDataReceived(true);
          setInProgress(false);
          if (res.data.length > 0) ScannerService.setScannerId(scannerId);
          clearTimeout(timeoutVar);
        } catch (error) {
          if (error.statusCode === 400 && error.message === 'Processing') {
            // Processing - check again
          } else {
            store.dispatch(setNotification({ message: 'Could not retrieve scan results', type: 'error' }));
            clearTimeout(timeoutVar);
          }
        }

        counter++;
        // Timer timed out
        if (counter === 30) clearTimeout(timeoutVar);
      }, 3000);
    } catch (error) {
      store.dispatch(setNotification({ message: 'Could not create scan', type: 'error' }));
      setInProgress(false);
      setNoOfTries(0);
    }
  };

  if (ScannerService.getScannerId() && isDataReceived === false) {
    return <FullPageLoader />;
  }

  return (
    <SimpleLayout id="scan-page">
      <Helmet>
        <title>{SeoService.getPageTitle('FREE Scan')}</title>
      </Helmet>

      <ScanResults isVisible={isDataReceived && scanResults.length > 0} scanResults={isDataReceived ? scanResults : [[]]} />

      <SmoothAnimate isVisible={scanResults.length === 0}>
        <div className="panel panel--form">
          <Welcome isVisible={!inProgress && !isDataReceived} handleSubmitSearch={handleSubmitSearch} />
          <Scanning isVisible={inProgress} />
          <TryAgain
            fullName={fullName}
            place={place}
            isVisible={!inProgress && isDataReceived && scanResults.length === 0 && noOfTries === 1}
            handleSubmitSearch={handleSubmitSearch}
          />
          <NotFound isVisible={!inProgress && isDataReceived && scanResults.length === 0 && noOfTries >= 2} />
        </div>
      </SmoothAnimate>
    </SimpleLayout>
  );
};
