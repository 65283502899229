import { AuthService } from '../Auth.service';
import { LoginResponse } from './AuthApi';
import { get, post, put } from './config';
import { ProfileResponse } from './ProfilesApi';

type CreateUserRequest = {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  birthday: string;
  street: string;
  city: string;
  state: string;
  zip: string;
};

type UpdateUserRequest = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  birthday?: string;
  street?: string;
  city?: string;
  state?: string;
  zip?: string;
};

export type ProfileWithStats = {
  profile: ProfileResponse;
  onerepStatus: string;
  new: number;
  optout_in_progresss: number;
  waiting_for_verification: number;
  removed: number;
  total: number;
};

export type AdminFullUserResponse = {
  user: FullUserResponse;
  profilesWithStats: ProfileWithStats[];
};

export type FullUserResponse = UserResponse & {
  profiles: ProfileResponse[];
  stripeMeta: StripeMetaResponse;
};

type CreateUserResponse = LoginResponse;

export type UserResponse = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  activated: boolean;
  birthday: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  role: 'ghost' | 'admin';
  profiles: ProfileResponse[];
  stripeMeta?: StripeMetaResponse;
  updatedAt: string;
  createdAt: string;
};

export type StripeMetaResponse = {
  id: string;
  paymentMethodId?: string;
  last4?: string;
  cardBrand?: string;
  customerId?: string;
  clientSecret?: string;
  priceId?: string;
  productId?: string;
  subscriptionId?: string;
  paymentIntentStatus?: 'succeeded' | 'requires_payment_method' | 'requires_action';
  invoiceStatus?: 'open' | 'paid';
  subscriptionStatus?: 'incomplete' | 'incomplete_expired' | 'trialing' | 'active' | 'past_due' | 'canceled' | 'unpaid';
  updatedAt: string;
  createdAt: string;
};

export class UsersApi {
  static create = async (params: CreateUserRequest) => await post<CreateUserResponse>('/users', params);

  static update = async (userId: string, params: UpdateUserRequest) =>
    await put<UserResponse>('/users/' + userId, params, AuthService.getAuthHeader());

  static findOne = async (id: string) => await get<FullUserResponse>('/users/' + id, AuthService.getAuthHeader());

  static findAll = async () => await get<AdminFullUserResponse[]>('/users/', AuthService.getAuthHeader());

  static resendActivationEmail = async (userId: string) => await post<{}>(`/users/${userId}/resend-activation-email`);
}
