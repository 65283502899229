import { useSelector } from 'react-redux';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { SimpleContent } from '../../components/layout/simple-content/SimpleContent';
import { SimpleLayout } from '../../components/layout/simple-layout/SimpleLayout';
import { AppState, getReduxStoreInstance } from '../../redux/store';
import stripeBadge from '../../img/stripe/stripe-badge.svg';
import { useEffect, useState } from 'react';
import { PricePreviewResponse, SubscriptionsApi } from '../../services/api/SubscriptionsApi';
import { UtilService } from '../../services/Util.service';
import { Btn } from '../../components/inputs/Btn';
import { SITEMAP } from '../../config/SITEMAP';
import { Loader } from '../../components/utils/Loader';
import { NotFoundPage } from '../not-found/NotFoundPage';
import { Helmet } from 'react-helmet';
import { SeoService } from '../../services/SeoService';
import { CheckoutPanel } from './components/CheckoutPanel';
import { setNotification } from '../../redux/actions/notificationAction';
import { InfoRow } from '../../components/inputs/form-parts/InfoRow';
import { PromotionCodePanel } from './components/PromotionCodePanel';

export const CheckoutPage = () => {
  const [isLoading, setLoading] = useState(true);
  const [iHavePromotionCode, setIHavePromotionCode] = useState(false);
  const [promotionCode, setPromotionCode] = useState('');
  const [promotionCodeLoading, setPromotionCodeLoading] = useState(false);
  const store = getReduxStoreInstance();
  const user = useSelector((state: AppState) => state.user);
  const stripeMeta = user.stripeMeta;
  const [currentPrice, setCurrentPrice] = useState<PricePreviewResponse>({
    regularPrice: 0,
    nextInvoicePrice: 0,
    stripePriceId: '',
    description: '0 profiles',
  });
  const location = useLocation<{ isAnnually: boolean }>();
  const history = useHistory();

  useEffect(() => {
    refreshPricePreview();

    // Testing FB Pixel
    try {
      const fbqInReact = (window as any).fbq;
      if (fbqInReact) {
        fbqInReact('track', 'StartedCheckout');
      }
    } catch (error) {
      console.log('FB Pixel Track failed');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAnnually = location.state?.isAnnually;

  const refreshPricePreview = async () => {
    if (isAnnually !== undefined)
      SubscriptionsApi.getPricePreview(user.id, { numberOfProfiles: user.profiles.length, annual: isAnnually })
        .then((res) => {
          setCurrentPrice(res.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
  };

  const handleCouponSubmit = (coupon: string) => {
    setPromotionCodeLoading(true);
    SubscriptionsApi.getPricePreview(user.id, { numberOfProfiles: user.profiles.length, annual: isAnnually, promotionCode: coupon })
      .then((res) => {
        if (res.data.promotionCode) {
          store.dispatch(setNotification({ message: 'Promotion code will be applied on the subscription', type: 'success' }));
          setPromotionCode(res.data.promotionCode);
          setIHavePromotionCode(false);
        } else {
          store.dispatch(setNotification({ message: 'Could not verify promotion code', type: 'error' }));
        }
        setCurrentPrice(res.data);
        setPromotionCodeLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.statusCode === 404) store.dispatch(setNotification({ message: error.message, type: 'error' }));
        else if (error.statusCode === 400) store.dispatch(setNotification({ message: error.message, type: 'error' }));
        else store.dispatch(setNotification({ message: 'Promotion code is not valid', type: 'error' }));
        setPromotionCodeLoading(false);
      });
  };

  if (!location.state)
    return (
      <SimpleLayout id="simple-central-page">
        <h1>Nothing to checkout</h1>
        <Btn label="Go to subscription options" onClick={(e) => history.push(SITEMAP.CUSTOMIZE_PLAN)} />
      </SimpleLayout>
    );

  if (stripeMeta.paymentIntentStatus === 'requires_payment_method') return <Redirect to={SITEMAP.ACCOUNT} />;
  if (stripeMeta && stripeMeta.subscriptionId === 'active') return <NotFoundPage />;

  if (isLoading)
    return (
      <SimpleLayout id="simple-central-page">
        <h1>Please wait</h1>
        <Loader id="simple-central-loader" color="blue" />
        <p>Finalizing our offer</p>
      </SimpleLayout>
    );

  return (
    <SimpleLayout>
      <Helmet>
        <title>{SeoService.getPageTitle('Checkout')}</title>
      </Helmet>

      <SimpleContent title="Billing information">
        <div className="checkout-page">
          <h1 className="title">Total: {UtilService.toDollars(currentPrice.nextInvoicePrice)}</h1>
          <div className="billing-cycle">
            {UtilService.toDollars(currentPrice.regularPrice)} billed {isAnnually ? 'anually' : 'monthly'}
          </div>
          {currentPrice.promotionCode && (
            <InfoRow message={`Promotion code ${currentPrice.promotionCode} will be applied on the subscription.`} type="success" />
          )}
          <div className="card-options">
            <CheckoutPanel isAnnually={isAnnually} promotionCode={promotionCode} amount={currentPrice.regularPrice} />
          </div>
          <div className="payment-options">
            <button className="link" onClick={() => setIHavePromotionCode(!iHavePromotionCode)}>
              {!currentPrice.promotionCode && !iHavePromotionCode
                ? `I have a promotion code`
                : !currentPrice.promotionCode && iHavePromotionCode && `I don't have a promotion code`}
            </button>
            <img src={stripeBadge} alt="Powered by Stripe" />
          </div>
          {iHavePromotionCode && <PromotionCodePanel isLoading={promotionCodeLoading} onSubmit={handleCouponSubmit} />}
          <div className="notes">
            <p>
              <Link to={SITEMAP.CUSTOMIZE_PLAN}>Back to subscription options</Link>
            </p>
          </div>
        </div>
      </SimpleContent>
    </SimpleLayout>
  );
};
