import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { Btn } from '../../components/inputs/Btn';
import { SimpleLayout } from '../../components/layout/simple-layout/SimpleLayout';
import { Loader } from '../../components/utils/Loader';
import { SITEMAP } from '../../config/SITEMAP';
import { setUser } from '../../redux/actions/user.action';
import { getReduxStoreInstance } from '../../redux/store';
import { AuthApi } from '../../services/api/AuthApi';
import { SeoService } from '../../services/SeoService';

export const ActivateUserPage = () => {
  const { userId, code } = useParams<{ userId: string; code: string }>();
  const [isActivated, setIsActivated] = useState<boolean | undefined>(undefined);
  let history = useHistory();
  const store = getReduxStoreInstance();

  useEffect(() => {
    const handleActivateUser = async (userId: string, code: string) => {
      try {
        const res = await AuthApi.activateUser(userId, code);
        store.dispatch(setUser(res.data));
        setIsActivated(true);
      } catch (error) {
        setIsActivated(false);
      }
    };

    if (!userId || !code) history.push(SITEMAP.DASHBOARD);

    handleActivateUser(userId, code);
  }, [code, history, store, userId]);

  return (
    <SimpleLayout id="simple-central-page">
      <Helmet>
        <title>{SeoService.getPageTitle('Activate User')}</title>
      </Helmet>
      {isActivated === undefined ? (
        <>
          <h1>Please wait</h1>
          <Loader id="simple-central-loader" color="blue" />
          <p>Activating your account</p>
        </>
      ) : isActivated === true ? (
        <>
          <h1>Activated!</h1>
          <p>Your account has been succesfully activated.</p>
          <Btn label="Go to Dashboard" onClick={(e) => history.push(SITEMAP.DASHBOARD)} />
        </>
      ) : (
        <>
          <h1>Oops!</h1>
          <p>A strange problem occured while activating your account. Please try again or contact support.</p>
        </>
      )}
    </SimpleLayout>
  );
};
