import moment from 'moment';
import { useEffect, useState } from 'react';
import { DashboardSection } from '../../../components/layout/dashboard-layout/DashboardSection';
import { Table } from '../../../components/layout/table/Table';
import { DateFilterForm } from '../../../forms/filter/DateFilterForm';
import {
  HistoricalRecordsStatsResponse,
  HistoricalScanResultsStatsResponse,
  ProfilesStatsResponse,
  ScannerApi,
  ScansStatsResponse,
} from '../../../services/api/ScannerApi';

const initialFrom = moment().subtract(1, 'months').toISOString();
const initialTo = moment().toISOString();

export const AdminStatsPage = () => {
  const [scansStats, setScansStats] = useState<ScansStatsResponse>();
  const [profilesStats, setProfilesStats] = useState<ProfilesStatsResponse>();
  const [historicalScanResults, setHistoricalScanResults] = useState<HistoricalScanResultsStatsResponse>();
  const [historicalRecords, setHistoricalRecords] = useState<HistoricalRecordsStatsResponse>();

  useEffect(() => {
    fetchScansStats(initialFrom, initialTo);
    fetchProfilesStats(initialFrom, initialTo);
    fetchHistoricalScanResultsStats(initialFrom, initialTo);
    fetchHistoricalRecordsStats(initialFrom, initialTo);
  }, []);

  const fetchScansStats = async (from: string, to: string) => {
    setScansStats(undefined);
    ScannerApi.getScansStats(moment(from).toISOString(), moment(to).toISOString()).then((res) => setScansStats(res.data));
  };

  const fetchProfilesStats = async (from: string, to: string) => {
    setProfilesStats(undefined);
    ScannerApi.getProfilesStats(moment(from).toISOString(), moment(to).toISOString()).then((res) => setProfilesStats(res.data));
  };

  const fetchHistoricalScanResultsStats = async (from: string, to: string) => {
    setHistoricalScanResults(undefined);
    ScannerApi.getHistoricalScanResultsStats(moment(from).toISOString(), moment(to).toISOString()).then((res) =>
      setHistoricalScanResults(res.data),
    );
  };

  const fetchHistoricalRecordsStats = async (from: string, to: string) => {
    setHistoricalRecords(undefined);
    ScannerApi.getHistoricalRecordsStats(moment(from).toISOString(), moment(to).toISOString()).then((res) =>
      setHistoricalRecords(res.data),
    );
  };

  return (
    <>
      <DashboardSection title="Scans Stats">
        <DateFilterForm onSubmit={fetchScansStats} from={initialFrom} to={initialTo} />
        <Table
          loading={scansStats === undefined}
          header={[
            { name: 'monitoring', value: 'Monitoring', width: 15 },
            { name: 'manual', value: 'Manual', width: 15 },
            { name: 'initial', value: 'Initial', width: 15 },
            { name: 'total', value: 'Total', width: 15 },
          ]}
          rowsPerPage={1000}
          hidePagination={true}
          content={
            scansStats
              ? [
                  {
                    data: [
                      { name: 'monitoring', value: scansStats.monitoring.toString() },
                      { name: 'manual', value: scansStats.manual.toString() },
                      { name: 'initial', value: scansStats.initial.toString() },
                      { name: 'total', value: scansStats.total.toString() },
                    ],
                  },
                ]
              : []
          }
        />
      </DashboardSection>
      <DashboardSection title="Profiles Stats">
        <DateFilterForm onSubmit={fetchProfilesStats} from={initialFrom} to={initialTo} />
        <Table
          loading={profilesStats === undefined}
          header={[
            { name: 'created', value: 'Created', width: 12 },
            { name: 'deleted', value: 'Deleted', width: 12 },
            { name: 'activated', value: 'Activated', width: 12 },
            { name: 'reactivated', value: 'Reactivated', width: 12 },
            { name: 'deactivated', value: 'Deactivated', width: 12 },
            { name: 'total_active', value: 'Total active', width: 12 },
            { name: 'total_inactive', value: 'Total inactive', width: 12 },
            { name: 'total', value: 'Total', width: 12 },
          ]}
          rowsPerPage={1000}
          hidePagination={true}
          content={
            profilesStats
              ? [
                  {
                    data: [
                      { name: 'created', value: profilesStats.created.toString() },
                      { name: 'deleted', value: profilesStats.deleted.toString() },
                      { name: 'activated', value: profilesStats.activated.toString() },
                      { name: 'reactivated', value: profilesStats.reactivated.toString() },
                      { name: 'deactivated', value: profilesStats.deactivated.toString() },
                      { name: 'total_active', value: profilesStats.total_active.toString() },
                      { name: 'total_inactive', value: profilesStats.total_inactive.toString() },
                      { name: 'total', value: profilesStats.total.toString() },
                    ],
                  },
                ]
              : []
          }
        />
      </DashboardSection>
      <DashboardSection title="Historical Scan Results">
        <DateFilterForm onSubmit={fetchHistoricalScanResultsStats} from={initialFrom} to={initialTo} />
        <Table
          loading={historicalScanResults === undefined}
          header={[
            { name: 'created', value: 'Created', width: 15 },
            { name: 'submitted_for_optout', value: 'Submitted for optout', width: 15 },
            { name: 'removed', value: 'Removed', width: 15 },
            { name: 'filtered', value: 'Filtered', width: 15 },
          ]}
          rowsPerPage={1000}
          hidePagination={true}
          content={
            historicalScanResults
              ? [
                  {
                    data: [
                      { name: 'created', value: historicalScanResults.created.toString() },
                      { name: 'submitted_for_optout', value: historicalScanResults.submitted_for_optout.toString() },
                      { name: 'removed', value: historicalScanResults.removed.toString() },
                      { name: 'filtered', value: historicalScanResults.filtered.toString() },
                    ],
                  },
                ]
              : []
          }
        />
      </DashboardSection>
      <DashboardSection title="Historical Records">
        <DateFilterForm onSubmit={fetchHistoricalRecordsStats} from={initialFrom} to={initialTo} />
        <Table
          loading={historicalRecords === undefined}
          header={[
            { name: 'created', value: 'Created', width: 15 },
            { name: 'submitted_for_optout', value: 'Submitted for optout', width: 15 },
            { name: 'removed', value: 'Removed', width: 15 },
            { name: 'filtered', value: 'Filtered', width: 15 },
          ]}
          rowsPerPage={1000}
          hidePagination={true}
          content={
            historicalRecords
              ? [
                  {
                    data: [
                      { name: 'created', value: historicalRecords.created.toString() },
                      { name: 'submitted_for_optout', value: historicalRecords.submitted_for_optout.toString() },
                      { name: 'removed', value: historicalRecords.removed.toString() },
                      { name: 'filtered', value: historicalRecords.filtered.toString() },
                    ],
                  },
                ]
              : []
          }
        />
      </DashboardSection>
    </>
  );
};
