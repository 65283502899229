import classNames from 'classnames';

type PropsType = {
  message: string;
  type: 'success' | 'error';
};

export const InfoRow = (props: PropsType) => {
  return (
    <div className={classNames({ inforow: true, ['inforow--' + props.type]: true })}>
      <span>{props.message}</span>
    </div>
  );
};
