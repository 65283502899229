import { Btn } from '../../../../components/inputs/Btn';
import { DashboardPanel } from '../../../../components/layout/dashboard-layout/DashboardPanel';
import pdfImage from '../../../../img/resources/pdf-resource.svg';
import videoImage from '../../../../img/resources/video-resource.svg';
import linkImage from '../../../../img/resources/link-resource.svg';

type PropsType = {
  type: 'pdf' | 'video' | 'link';
  title: string;
  description: string;
  url: string;
  locked: boolean;
};

export const ResourceItem = (props: PropsType) => {
  return (
    <DashboardPanel className="resource-panel">
      <div className="resource-panel__content">
        <div className="resource-panel__content__icon">
          {props.type === 'pdf' && <img src={pdfImage} alt="PDF document resource" />}
          {props.type === 'video' && <img src={videoImage} alt="Video resource" />}
          {props.type === 'link' && <img src={linkImage} alt="Link resource" />}
        </div>
        <div className="resource-panel__content__info">
          <h3>{props.title}</h3>
          <p>{props.description}</p>
        </div>
      </div>
      <div className="resource-panel__controls">
        <Btn
          asLink={true}
          label={props.type === 'link' ? 'Open' : 'Download'}
          icon={props.type === 'link' ? 'link' : 'download'}
          size="medium"
          locked={props.locked}
          to={props.url}
        />
      </div>
    </DashboardPanel>
  );
};
