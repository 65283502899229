import { createStore, combineReducers } from 'redux';
import { notificationReducer } from './reducers/notificationReducer';
import { userReducer } from './reducers/user.reducer';

export const rootReducer = combineReducers({ user: userReducer, notifications: notificationReducer });

const store = createStore(rootReducer, (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());

export const getReduxStoreInstance = () => {
  return store;
};

export type AppState = ReturnType<typeof rootReducer>; // Will make AppState a type of all types supplied in the combineReducers
