import { SmoothAnimate } from '../../../components/smooth-animate/SmoothAnimate';
import searchImage from '../../../img/icons/search-image.svg';
import { SearchForm } from './SearchForm';

type PropsType = {
  isVisible: boolean;
  handleSubmitSearch: (fullName: string, city: string, recaptchaToken: string) => void;
};

export const Welcome = (props: PropsType) => {
  return (
    <SmoothAnimate isVisible={props.isVisible}>
      <img className="panel__icon" src={searchImage} alt="Scan" />
      <h1 className="panel__title">Start a FREE scan and find out if your information is exposed</h1>
      <p className="panel__description">
        We​ ​are​ ​ready to scan ​the​ ​most​ ​popular​ ​data brokers​ and background check ​websites to​ ​see​ ​whether​ ​they​ ​have​
        ​posted​ ​your personal information.
      </p>
      <SearchForm handleSubmitSearch={props.handleSubmitSearch} />
    </SmoothAnimate>
  );
};
