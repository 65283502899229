import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AppState } from '../redux/store';

export const PublicRoute = (props: RouteProps) => {
  const user = useSelector((state: AppState) => state.user);

  if (Object.keys(user).length === 0) return <Route {...props} />;

  return <Redirect to="/" />;
};
