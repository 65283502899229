export class HubspotService {
  static showHubspotChat() {
    document.body.classList.remove('hide-hubspot');
    (window as any).HubSpotConversations.widget.open();
  }

  static hideHubspotChat() {
    document.body.classList.add('hide-hubspot');
    (window as any).HubSpotConversations?.widget?.close();
  }
}
