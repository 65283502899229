import {
  ACTIVATE_USER,
  ADD_PROFILE,
  AppActions,
  REMOVE_PROFILE,
  REMOVE_USER,
  SET_PROFILES,
  SET_STRIPE_META,
  SET_USER,
  UPDATE_PROFILE,
  UPDATE_USER,
} from '../actions/user.action';
import { UserStoreType } from '../types/user.type';

const reducerInitialState: UserStoreType = {} as UserStoreType;

const userReducer = (state = reducerInitialState, action: AppActions): UserStoreType => {
  switch (action.type) {
    case SET_USER:
      return action.user;

    case ACTIVATE_USER:
      return { ...state, activated: true };

    case UPDATE_USER:
      return { ...state, ...action.user };

    case REMOVE_USER:
      return {} as UserStoreType;

    case SET_PROFILES:
      return { ...state, profiles: action.profiles };

    case ADD_PROFILE:
      return { ...state, profiles: [...state.profiles, action.profile] };

    case UPDATE_PROFILE:
      const updatedProfiles = state.profiles.map((profile) => (profile.id === action.profile.id ? action.profile : profile));
      return { ...state, profiles: updatedProfiles };

    case REMOVE_PROFILE:
      const removedProfiles = state.profiles.filter((profile) => profile.id !== action.profileId);
      return { ...state, profiles: removedProfiles };

    case SET_STRIPE_META:
      return { ...state, stripeMeta: action.stripeMeta };

    default:
      return state;
  }
};

export { userReducer };
