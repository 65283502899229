import classNames from 'classnames';
import { useField } from 'formik';
import { FormikRichInputType } from './text-input-parts/FormikRichInput.type';
import { TextInputError } from './text-input-parts/TextInputError';
import { TextInputLabel } from './text-input-parts/TextInputLabel';
import { TextInputWrapper } from './text-input-parts/TextInputWrapper';

export const TextInput = (props: FormikRichInputType) => {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;

  return (
    <TextInputWrapper id={props.id || props.name}>
      <TextInputLabel for={props.id || props.name} label={props.label} required={props.required} />
      <input
        id={props.id || props.name}
        type={props.type}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        {...field}
        className={classNames({ error: showError })}
      />
      <TextInputError showError={!!showError && !props.hideErrorLabel} error={meta.error} />
    </TextInputWrapper>
  );
};
