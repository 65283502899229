import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DashboardPanel } from '../../../components/layout/dashboard-layout/DashboardPanel';
import { Table } from '../../../components/layout/table/Table';
import { AppState } from '../../../redux/store';
import { ProfilesApi, RecordResponse, RecordsResponse } from '../../../services/api/ProfilesApi';
import inProgressIcon from '../../../img/profile-status/in_progress.svg';
import attentionIcon from '../../../img/profile-status/attention.svg';
import cleanIcon from '../../../img/profile-status/clean.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { DashboardLoader } from '../../../components/utils/dashboard-loader/DashboardLoader';
import { Helmet } from 'react-helmet';
import { SeoService } from '../../../services/SeoService';
import { UtilService } from '../../../services/Util.service';
import { DashboardContext } from '../../../components/layout/dashboard-layout/DashboardLayout';

export const ProfilePage = () => {
  const { profileId } = useParams<{ profileId: string }>();
  const user = useSelector((state: AppState) => state.user);
  const profile = user.profiles.find((profile) => profile.id === profileId);

  const dashboardContext = useContext(DashboardContext);

  const [records, setRecords] = useState<RecordsResponse | undefined>(undefined);
  const [scanning, setScanning] = useState(false);

  const { hash } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const fetchSingleRecords = async (userId: string, profileId: string) => {
      try {
        const res = await ProfilesApi.getRecords(userId, profileId);
        setRecords(res.data);
        setScanning(false);
      } catch (error) {
        if (error.statusCode === 400) {
          setScanning(true);
          setTimeout(() => {
            fetchSingleRecords(userId, profileId);
          }, 3000);
        }
      }
    };

    const fetchAllUserRecords = async (userId: string) => {
      try {
        const res = await ProfilesApi.getAllUserRecords(userId);
        setRecords(res.data);
        setScanning(false);
      } catch (error) {
        if (error.statusCode === 400) {
          setScanning(true);
          setTimeout(() => {
            fetchAllUserRecords(userId);
          }, 3000);
        }
      }
    };

    if (profile) {
      dashboardContext.setDashboardLocation(
        `Profile Scanner / ${UtilService.getFullName(profile.firstName, profile.middleName, profile.lastName)}`,
      );
      fetchSingleRecords(user.id, profile.id);
    } else {
      dashboardContext.setDashboardLocation('Profile Scanner / All Profiles');
      fetchAllUserRecords(user.id);
    }
  }, [dashboardContext, profile, user.id]);

  if (records === undefined && scanning === false) return <DashboardLoader />;

  if (scanning)
    return (
      <DashboardPanel help="Scanning">
        <div className="profile-status">
          <div className="profile-status__icon">
            <img src={inProgressIcon} alt="In progress" className="in-progress" />
          </div>
          <div className="profile-status__info">
            <div className="profile-status__info__label">Status</div>
            <h1>Scanning your profile</h1>
          </div>
        </div>
      </DashboardPanel>
    );

  if (records === undefined) return <DashboardLoader />;

  const subActiveAndEmailActivated = user.stripeMeta.subscriptionStatus === 'active' && user.activated;

  // Determine records
  const newRecords = [...records.new, ...records.optout_in_progress];
  // If the plan is ACTIVE, all NEW records must be in opt-out phase
  const inProgressRecords = subActiveAndEmailActivated ? [...records.new, ...records.optout_in_progress] : records.optout_in_progress;
  const removedRecords = records.removed;

  // Determine current status
  let status: 'stopped' | 'inProgress' | 'clean';

  if (!subActiveAndEmailActivated) status = 'stopped';
  else if (inProgressRecords.length > 0) status = 'inProgress';
  else status = 'clean';

  // Determine active tab
  let activeTab: 'new' | 'inProgress' | 'removed' = 'new';
  if (hash === `#new`) activeTab = 'new';
  else if (hash === `#inProgress`) activeTab = 'inProgress';
  else if (hash === `#removed`) activeTab = 'removed';

  return (
    <div>
      <Helmet>
        <title>
          {SeoService.getPageTitle(
            profile ? UtilService.getFullName(profile.firstName, profile.middleName, profile.lastName) + ' Profile' : 'Profile Scanner',
          )}
        </title>
      </Helmet>
      <DashboardPanel
        help={
          status === 'inProgress'
            ? 'We’re doing our best to remove all online records that hold your personal information. Stay tuned for updates!'
            : status === 'clean'
            ? 'All records we’ve found so far were successfully removed. We’re doing continous monitoring in case we find a new ones.'
            : 'Your private information removal stopped because you don’t have any subscription plan active or e-mail address isn’t verified.'
        }
      >
        <div className="profile-status">
          <div className="profile-status__icon">
            {status === 'stopped' && <img src={attentionIcon} alt="Attention" />}
            {status === 'inProgress' && <img src={inProgressIcon} alt="In progress" className="in-progress" />}
            {status === 'clean' && <img src={cleanIcon} alt="Clean" />}
          </div>
          <div className="profile-status__info">
            <div className="profile-status__info__label">Status</div>
            <h1>
              {status === 'stopped' && 'Removal stopped'}
              {status === 'inProgress' && 'Removal in progress'}
              {status === 'clean' && 'You’re all clean! '}
            </h1>
          </div>
        </div>
      </DashboardPanel>
      <div className="records">
        <div className="records__header">
          <button className={classNames({ new: true, active: activeTab === 'new' })} onClick={() => history.push({ hash: 'new' })}>
            <div className="number">{newRecords.length}</div>
            <div className="label">Records found</div>
          </button>
          <button
            className={classNames({ inProgress: true, active: activeTab === 'inProgress' })}
            onClick={() => history.push({ hash: 'inProgress' })}
          >
            <div className="number">{inProgressRecords.length}</div>
            <div className="label">Removal in progress</div>
          </button>
          <button
            className={classNames({ removed: true, active: activeTab === 'removed' })}
            onClick={() => history.push({ hash: 'removed' })}
          >
            <div className="number">{removedRecords.length}</div>
            <div className="label">Records removed</div>
          </button>
        </div>
        <div className="records__content">
          {activeTab === 'new' && <ProfileTab content={newRecords} />}
          {activeTab === 'inProgress' && <ProfileTab content={inProgressRecords} />}
          {activeTab === 'removed' && <ProfileTab content={removedRecords} />}
        </div>
      </div>
    </div>
  );
};

type ProfileTabPropsType = {
  content: RecordResponse[];
};

const ProfileTab = (props: ProfileTabPropsType) => {
  return (
    <Table
      header={[
        { name: 'dataBroker', value: 'Data Broker', width: 25 },
        { name: 'profile', value: 'Profile', width: 20 },
        { name: 'location', value: 'Location', width: 25 },
        { name: 'phone', value: 'Phone', width: 15 },
      ]}
      rowsPerPage={10}
      content={
        props.content &&
        props.content.map((record) => ({
          url: record.url,
          data: [
            { name: 'dataBroker', value: record.dataBroker },
            { name: 'profile', value: record.profile },
            { name: 'location', value: record.location },
            { name: 'phone', value: record.phone },
          ],
        }))
      }
    />
  );
};
