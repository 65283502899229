import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';
import nothingIcon from '../../../img/icons/nothing.svg';
import { FullDivLoaderWrapper } from '../../utils/full-div-loader/FullDivLoaderWrapper';
import { Loader } from '../../utils/Loader';

type PropsType = {
  loading?: boolean;
  header: {
    name: string;
    value: string;
    width: number;
  }[];
  widthUnit?: string;
  content: undefined | TableDataFormat;
  rowsPerPage: number;
  hidePagination?: boolean;
  emptyText?: string;
};

export type TableDataFormat = TableRowFormat[];

type TableRowFormat = { url?: string; extra?: React.ReactNode; data: { name: string; value?: string | number | boolean }[] };

export const Table = (props: PropsType) => {
  const [page, setCurrentPage] = useState<number>(1);
  const [openExtraId, setOpenExtraId] = useState<number | undefined>();
  let splittedContent = props.content;

  const setPage = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (props.content !== undefined) document.getElementsByClassName('table')[0].scrollIntoView();
  };

  if (props.loading) {
    return (
      <div className="table-wrapper">
        <div className="table">
          <div className="loading">
            <Loader color="blue" />
          </div>
        </div>
      </div>
    );
  }

  if (props.content === undefined || (props.content !== undefined && props.content.length === 0)) {
    return (
      <div className="table-wrapper">
        <div className="table">
          <div className="empty">
            <img src={nothingIcon} alt="Nothing found" />
            <span>{props.emptyText || 'No records found'}</span>
          </div>
        </div>
      </div>
    );
  }

  const minPage = 1;
  const maxPage = Math.ceil(props.content.length / props.rowsPerPage);

  const getRelatedPageButton = (relatedNumber: number) => {
    const newPage = page + relatedNumber;
    if (checkIfPageOk(newPage)) {
      return (
        <button className="number" onClick={(e) => setPage(newPage)}>
          {newPage}
        </button>
      );
    }
  };

  const getLeftPageArrow = () => {
    const newPage = page - 1;
    return <button disabled={!checkIfPageOk(newPage)} className="arrow arrow--left" onClick={(e) => setPage(newPage)} />;
  };

  const getRightPageArrow = () => {
    const newPage = page + 1;
    return <button disabled={!checkIfPageOk(newPage)} className="arrow arrow--right" onClick={(e) => setPage(newPage)} />;
  };

  const checkIfPageOk = (page: number) => page >= minPage && page <= maxPage;

  splittedContent = props.content.slice((page - 1) * props.rowsPerPage, page * props.rowsPerPage);

  const getRowWidth = () => {
    let sum = 0;
    for (const cell of props.header) sum += cell.width;
    return sum;
  };

  const getCellWidth = (cellWidth: number) => {
    return cellWidth + (props.widthUnit || 'rem');
  };

  const everyRowWidth = getRowWidth() + 0.1 * props.header.length + (props.widthUnit || 'rem');
  const everyRowStyle = { minWidth: everyRowWidth };

  return (
    <div className="table-wrapper">
      <FullDivLoaderWrapper loading={props.loading || false} />
      <div className="table">
        <div className="row row--header" style={everyRowStyle}>
          <div className="row__content">
            {props.header.map((cell) => (
              <div key={`cell-${cell.name}`} className={`cell cell--${cell.name}`} style={{ width: getCellWidth(cell.width) }}>
                <span>{cell.value}</span>
              </div>
            ))}
          </div>
        </div>
        {splittedContent.map((row, idx) => {
          let rowContent = (
            <div key={`row__content-${idx}`} className="row__content">
              {props.header.map((head) => (
                <div key={`row-${idx}-cell-${head.name}`} className={`cell cell--${head.name}`} style={{ width: getCellWidth(head.width) }}>
                  <span>{row.data.find((rowItem) => rowItem.name === head.name)?.value?.toString()}</span>
                </div>
              ))}
            </div>
          );
          if (row.extra) {
            rowContent = (
              <div key={`row--extra-${idx}`} className="row row--extra" style={everyRowStyle}>
                {React.cloneElement(rowContent, {
                  onClick: () => {
                    if (openExtraId === idx) setOpenExtraId(undefined);
                    else setOpenExtraId(idx);
                  },
                })}
                <div className={classNames({ row__extra: true, open: idx === openExtraId })}>{row.extra}</div>
              </div>
            );
          } else if (row.url) {
            rowContent = (
              <a key={`row--url-${idx}`} className="row row--url" style={everyRowStyle} href={row.url} target="_blank" rel="noreferrer">
                {rowContent}
              </a>
            );
          } else {
            rowContent = (
              <div key={`row-${idx}`} className="row row--normal" style={everyRowStyle}>
                {rowContent}
              </div>
            );
          }
          return rowContent;
        })}
      </div>
      {!props.hidePagination && (
        <div className="pagination">
          {getLeftPageArrow()}
          {getRelatedPageButton(-2)}
          {getRelatedPageButton(-1)}
          <button onClick={(e) => setPage(page)} className="number active">
            {page}
          </button>
          {getRelatedPageButton(1)}
          {getRelatedPageButton(2)}
          {getRightPageArrow()}
        </div>
      )}
    </div>
  );
};
