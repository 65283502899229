type LabelProps = {
  for?: string;
  label: string;
  required?: boolean;
};

export const TextInputLabel = (props: LabelProps) => (
  <label htmlFor={props.for}>
    {props.label}
    {props.required && <span className="required">*</span>}
  </label>
);
