import { removeUser, setUser } from '../redux/actions/user.action';
import { getReduxStoreInstance } from '../redux/store';
import jsonwebtoken from 'jsonwebtoken';
import { FullUserResponse, UsersApi } from './api/UsersApi';

export class AuthService {
  static getAuthHeader = () => ({
    headers: {
      Authorization: 'Bearer ' + AuthService.getAccessToken(),
    },
  });

  static isValidToken() {
    const access_token = localStorage.getItem('access_token');
    return !!access_token;
  }

  static getAccessToken() {
    return localStorage.getItem('access_token');
  }

  static login(access_token: string, user: FullUserResponse) {
    const store = getReduxStoreInstance();
    localStorage.setItem('access_token', access_token);
    store.dispatch(setUser(user));
  }

  static async autoLogin() {
    const access_token = this.getAccessToken();
    if (access_token) {
      const decoded = jsonwebtoken.decode(access_token);
      const userId = decoded?.sub;

      try {
        const { data } = await UsersApi.findOne(userId);

        this.login(access_token, data);
        return true;
      } catch (error) {
        this.logout();
        return false;
      }
    }
  }

  static logout() {
    const store = getReduxStoreInstance();
    localStorage.removeItem('access_token');
    store.dispatch(removeUser());
  }
}
