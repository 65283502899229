import visa from '../../../img/cards/visa.svg';
import master from '../../../img/cards/master.svg';
import amex from '../../../img/cards/amex.svg';
import jcb from '../../../img/cards/jcb.svg';
import discover from '../../../img/cards/discover.svg';
import diners from '../../../img/cards/diners.svg';

type BriefCardInfoProps = {
  cardBrand: string;
  last4: string;
  activePlan?: React.ReactNode;
};

export const BriefCardInfo = (props: BriefCardInfoProps) => (
  <div className="brief-card-info">
    {props.cardBrand === 'visa' && <img className="card-brand" src={visa} alt="Visa" />}
    {props.cardBrand === 'master' && <img className="card-brand" src={master} alt="Master" />}
    {props.cardBrand === 'amex' && <img className="card-brand" src={amex} alt="Amex" />}
    {props.cardBrand === 'jcb' && <img className="card-brand" src={jcb} alt="jJcb" />}
    {props.cardBrand === 'discover' && <img className="card-brand" src={discover} alt="Discover" />}
    {props.cardBrand === 'diners' && <img className="card-brand" src={diners} alt="Diners" />}
    <div className="card-information">
      <span>
        Card: {props.cardBrand.toUpperCase()} *{props.last4}
      </span>
      {props.activePlan}
    </div>
  </div>
);
