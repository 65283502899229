import classNames from 'classnames';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';

type PropsType = {
  isVisible: boolean;
  children: React.ReactNode;
};

export const SmoothAnimate = ({ isVisible, children }: PropsType) => {
  const [internalShow, setInternalShow] = useState(isVisible);

  return (
    <AnimateHeight
      duration={2000}
      delay={500}
      onAnimationStart={() => {
        if (isVisible) {
          // SHOW -> IN Animation started; Nothing
          setInternalShow(true);
        } else {
          // HIDE -> IN Animation started; Show content
          setInternalShow(false);
        }
      }}
      onAnimationEnd={() => {
        if (isVisible) {
          // SHOW -> OUT Animation finished; Show content
        } else {
          // HIDE -> OUT Animation finished; Nothing
        }
      }}
      height={isVisible ? 'auto' : 0}
      contentClassName={classNames({
        'smooth-animate': true,
        hidden: !internalShow,
        visible: internalShow,
      })}
    >
      {children}
    </AnimateHeight>
  );
};
