import { ProfileResponse } from '../../services/api/ProfilesApi';
import { StripeMetaResponse, UserResponse } from '../../services/api/UsersApi';
import { UserStoreType } from '../types/user.type';

export const SET_USER = 'SET_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';

export const SET_PROFILES = 'SET_PROFILES';
export const ADD_PROFILE = 'ADD_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const REMOVE_PROFILES = 'REMOVE_PROFILES';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';

export const SET_STRIPE_META = 'SET_STRIPE_META';
export const REMOVE_STRIPE_META = 'REMOVE_STRIPE_META';

// User actions
export interface SetUser {
  type: typeof SET_USER;
  user: UserStoreType;
}
export interface ActivateUser {
  type: typeof ACTIVATE_USER;
}
export interface UpdateUser {
  type: typeof UPDATE_USER;
  user: UserResponse;
}
export interface RemoveUser {
  type: typeof REMOVE_USER;
}
export type UserActionTypes = SetUser | ActivateUser | UpdateUser | RemoveUser;

export const setUser = (user: UserStoreType): UserActionTypes => ({
  type: SET_USER,
  user: user,
});
export const activateUser = (): UserActionTypes => ({
  type: ACTIVATE_USER,
});
export const updateUser = (user: UserResponse): UserActionTypes => ({
  type: UPDATE_USER,
  user,
});
export const removeUser = (): UserActionTypes => ({ type: REMOVE_USER });

// Profile actions
export interface SetProfiles {
  type: typeof SET_PROFILES;
  profiles: ProfileResponse[];
}
export interface AddProfile {
  type: typeof ADD_PROFILE;
  profile: ProfileResponse;
}
export interface UpdateProfile {
  type: typeof UPDATE_PROFILE;
  profile: ProfileResponse;
}
export interface RemoveProfiles {
  type: typeof REMOVE_PROFILES;
}
export interface RemoveProfile {
  type: typeof REMOVE_PROFILE;
  profileId: string;
}
export type ProfilesActionTypes = SetProfiles | AddProfile | UpdateProfile | RemoveProfiles | RemoveProfile;

export const setProfiles = (profiles: ProfileResponse[]): ProfilesActionTypes => ({
  type: SET_PROFILES,
  profiles: profiles || [],
});
export const addProfile = (profile: ProfileResponse): ProfilesActionTypes => ({
  type: ADD_PROFILE,
  profile,
});
export const updateProfile = (profile: ProfileResponse): ProfilesActionTypes => ({
  type: UPDATE_PROFILE,
  profile,
});
export const removeProfiles = (): ProfilesActionTypes => ({ type: REMOVE_PROFILES });
export const removeProfile = (profileId: string): ProfilesActionTypes => ({ type: REMOVE_PROFILE, profileId });

// Stripe Meta actions
export interface SetStripeMeta {
  type: typeof SET_STRIPE_META;
  stripeMeta: StripeMetaResponse;
}
export type StripeMetaActionTypes = SetStripeMeta;

export const setStripeMeta = (stripeMeta: StripeMetaResponse): StripeMetaActionTypes => ({
  type: SET_STRIPE_META,
  stripeMeta,
});

// Sum up
export type AppActions = UserActionTypes | ProfilesActionTypes | StripeMetaActionTypes;
