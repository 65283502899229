import tip1 from '../img/security-tips/1.jpg';
import tip2 from '../img/security-tips/2.jpg';
import tip3 from '../img/security-tips/3.jpg';
import tip4 from '../img/security-tips/4.jpg';
import tip5 from '../img/security-tips/5.jpg';
import tip6 from '../img/security-tips/6.jpg';
import tip7 from '../img/security-tips/7.jpg';
import tip8 from '../img/security-tips/8.jpg';
import tip9 from '../img/security-tips/9.jpg';
import tip10 from '../img/security-tips/10.jpg';

export type SecurityTip = {
  description: string;
  image: string;
};

const securityTips: SecurityTip[] = [
  {
    description:
      'Most states have laws regulating what a business can do with information it collects from you, and requiring businesses to report data breaches. These laws do not cover the publicly available information that is collected and sold by third-party data brokers.',
    image: tip1,
  },
  {
    description:
      'Businesses combine personal information with other information such as online activity, social media posts, geolocation, or purchase information to create profiles of potential customers. These profiles might influence decisions about hiring, approval of a loan or mortgage, or insurance premiums, in addition to being used for marketing.',
    image: tip2,
  },
  {
    description:
      'Federal Judge Esther Salas has called for laws to protect the privacy of federal judges and their families after an antifeminist lawyer disguised as a Fedex driver knocked on her door, and shot her son and husband in July 2020. Information such as home addresses, email addresses, and telephone numbers can be used to stalk or harass someone.',
    image: tip3,
  },
  {
    description:
      'Property fraud occurs when someone uses your personal information to file documents that make it appear as though they own your home. Then they use your home as collateral for a loan, or take out a second mortgage on it.',
    image: tip4,
  },
  {
    description:
      'On the dark web, credit card details sell for as little as $12. The average price for online banking credentials to accounts with a balance of $2,000 or more is $65.  ',
    image: tip5,
  },
  {
    description:
      'With a stolen name and Social Security number, someone else can file your tax return and claim your tax refund. File your taxes early. You can make corrections to your tax return later if necessary.',
    image: tip6,
  },
  {
    description:
      'With just two pieces of personal data, such as a stolen Social Security number and full name, an identity thief can put together enough information about you to apply for credit cards in your name, and change the billing address so that you are unaware. When the bills are not paid, your credit is damaged and you might be unable to get a mortgage or a car loan.',
    image: tip7,
  },
  {
    description:
      'Once they have account user names, hackers can use software to “guess” thousands of possible passwords by combining letters, dates, and symbols, until they find one that works. Password managers are applications that generate secure passwords and store them safely for you in an encrypted database.',
    image: tip8,
  },
  {
    description:
      'Phishing involves using a fake email, document, or website to trick the user into revealing a user name and password, or clicking a link to install malicious software. Scammers use your personal data, together with information from the dark web, to create emails that can deceive even the most suspicious reader.',
    image: tip9,
  },
  {
    description:
      'Personal data for infants, stolen from pediatricians’ offices and hospitals, is sold on the dark web for $10 per set. The data includes full names, Social Security numbers, birthdates, and home addresses.',
    image: tip10,
  },
];

export const getRandomSecurityTip = () => {
  const date = new Date();
  const hours = date.getHours();
  const randomNumber = hours % securityTips.length;
  return securityTips[randomNumber];
};
