import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { NotFoundPage } from '../pages/not-found/NotFoundPage';
import { AppState } from '../redux/store';
import { AuthService } from '../services/Auth.service';

export const AdminRoute = (props: RouteProps) => {
  const user = useSelector((state: AppState) => state.user);

  if (AuthService.isValidToken() && user !== null && Object.keys(user).length !== 0 && user.role === 'admin') return <Route {...props} />;

  return <NotFoundPage />;
};
