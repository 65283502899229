import { AuthService } from '../Auth.service';
import { get, post, put, destroy } from './config';
import { StripeMetaResponse } from './UsersApi';

type UpdatePaymentMethodRequest = {
  paymentMethodId: string;
};

type PricePreviewRequest = {
  numberOfProfiles: number;
  annual: boolean;
  promotionCode?: string;
};

export type PricePreviewResponse = {
  regularPrice: number;
  stripePriceId: string;
  description: string;
  nextInvoicePrice: number;
  promotionCode?: string;
};

type CreateSubscriptionRequest = {
  isAnually: boolean;
  promotionCode?: string;
};

export type ReceiptResponse = {
  id: string;
  amount: number;
  currency: string;
  created: number;
  status: string;
  url: string;
};

export type UpcomingInvoiceResponse = {
  total: number;
  date: number;
};

export type SubscriptionResponse = {
  price: number;
  stripePriceId: string;
  productDescription: string;
  stripeProductId: string;
  nextPaymentDate: number;
  status: 'incomplete' | 'incomplete_expired' | 'trialing' | 'active' | 'past_due' | 'canceled' | 'unpaid';
  isAnnually: boolean;
};

export class SubscriptionsApi {
  static updatePaymentMethod = async (userId: string, params: UpdatePaymentMethodRequest) =>
    await put<StripeMetaResponse>(`users/${userId}/payment-methods`, params, AuthService.getAuthHeader());

  static removePaymentMethod = async (userId: string) =>
    await destroy<StripeMetaResponse>(`users/${userId}/payment-methods`, AuthService.getAuthHeader());

  static createSubscription = async (userId: string, params: CreateSubscriptionRequest) =>
    await put<StripeMetaResponse>(`users/${userId}/subscriptions`, params, AuthService.getAuthHeader());

  static cancelSubscription = async (userId: string) =>
    await destroy<StripeMetaResponse>(`users/${userId}/subscriptions`, AuthService.getAuthHeader());

  static getPricePreview = async (userId: string, params: PricePreviewRequest) =>
    await post<PricePreviewResponse>(`users/${userId}/subscriptions/price-preview`, params, AuthService.getAuthHeader());

  static getUpcomingInvoice = async (userId: string) =>
    await get<UpcomingInvoiceResponse>(`users/${userId}/subscriptions/upcoming-invoice`, AuthService.getAuthHeader());

  static getCurrentSubscription = async (userId: string) =>
    await get<SubscriptionResponse>(`users/${userId}/subscriptions`, AuthService.getAuthHeader());

  static getAllInvoices = async (userId: string) => await get<ReceiptResponse[]>(`users/${userId}/invoices`, AuthService.getAuthHeader());

  static applyPromoCode = async (userId: string, params: { promoCode: string }) =>
    await post(`users/${userId}/promo-codes`, params, AuthService.getAuthHeader());
}
