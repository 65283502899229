type PropsType = {
  label: string;
  onClick?: (e: React.MouseEvent) => void;
};

export const AddBtn = (props: PropsType) => (
  <button className="add-btn" onClick={props.onClick}>
    <span>{props.label}</span>
  </button>
);
