import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Btn } from '../../../components/inputs/Btn';
import { SmoothAnimate } from '../../../components/smooth-animate/SmoothAnimate';
import { SITEMAP } from '../../../config/SITEMAP';
import noResultsImage from '../../../img/icons/no-results-image.svg';
import { SeoService } from '../../../services/SeoService';

type PropsType = {
  isVisible: boolean;
};

export const NotFound = (props: PropsType) => {
  const history = useHistory();

  return (
    <SmoothAnimate isVisible={props.isVisible}>
      <Helmet>
        <title>{SeoService.getPageTitle('Scan Results')}</title>
      </Helmet>
      <img className="panel__icon" src={noResultsImage} alt="Nothing found" />
      <h1 className="panel__title">No records found.</h1>
      <p className="panel__description">
        This is a great news if information you’ve entered was correct, but still - we suggest you to proceed with registration and opt in
        to our protection in case some records pop up in the future.
      </p>
      <div className="panel__buttons">
        <Btn label="Sign up for monitoring" hasArrow={true} color="green" onClick={() => history.push(SITEMAP.REGISTER)} />
      </div>
    </SmoothAnimate>
  );
};
