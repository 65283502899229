import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { SITEMAP } from '../../../config/SITEMAP';
import { AppState } from '../../../redux/store';
import { UtilService } from '../../../services/Util.service';

type PropsType = {
  isOpen: boolean;
};

export const SidebarNav = (props: PropsType) => {
  const user = useSelector((state: AppState) => state.user);
  const profiles = user.profiles;

  return (
    <nav className={classNames({ 'dashboard-layout__sidebar__nav': true, 'dashboard-layout__sidebar__nav--open': props.isOpen })}>
      <ul>
        <li>
          {profiles.length > 0 && (
            <NavLink exact to={SITEMAP.DASHBOARD}>
              <span className=" icon icon-profile-scanner"></span>
              <span>Profile scanner</span>
            </NavLink>
          )}
          {profiles && (
            <ul>
              {profiles.map((profile) => (
                <li key={profile.id}>
                  <NavLink exact to={SITEMAP.PROFILE(profile.id)}>
                    <span className=" icon icon-profile"></span>
                    <span>{UtilService.getFullName(profile.firstName, profile.middleName, profile.lastName)}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </li>
        <li>
          <NavLink exact to={SITEMAP.RESOURCES}>
            <span className="icon icon-resource"></span>
            <span>Resources</span>
          </NavLink>
        </li>
        {user.role === 'admin' && (
          <>
            <li>
              <NavLink exact to={SITEMAP.ADMIN_USERS}>
                <span className="icon icon-profile"></span>
                <span>Users</span>
              </NavLink>
            </li>
            <li>
              <NavLink exact to={SITEMAP.ADMIN_STATS}>
                <span className="icon icon-profile"></span>
                <span>Stats</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};
