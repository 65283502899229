import { Btn } from '../../../../../components/inputs/Btn';
import { DashboardSection } from '../../../../../components/layout/dashboard-layout/DashboardSection';
import { DashboardPanel } from '../../../../../components/layout/dashboard-layout/DashboardPanel';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../redux/store';
import { UtilService } from '../../../../../services/Util.service';
import { Popable } from '../../../../../components/popable/Popable';
import { EmailForm } from '../../../../../forms/email/EmailForm';
import { AboutForm } from '../../../../../forms/about/AboutForm';
import { PasswordForm } from '../../../../../forms/password/PasswordForm';

type ItemPropsType = {
  title: string;
  info: React.ReactNode;
  btns: React.ReactNode;
};

const AccountSettingItem = (props: ItemPropsType) => (
  <div className="account-settings__item">
    <h3 className="account-settings__item__title">{props.title}</h3>
    <div className="account-settings__item__info">{props.info}</div>
    <div className="account-settings__item__btn">{props.btns}</div>
  </div>
);

export const AccountSettings = () => {
  const user = useSelector((state: AppState) => state.user);

  return (
    <DashboardSection title="Account">
      <DashboardPanel className="account-settings">
        <AccountSettingItem
          title="About"
          info={
            <>
              <span>Name: {UtilService.getFullName(user.firstName, user.middleName, user.lastName)}</span>
              <span>Date of birth: {UtilService.toReadableDateFormat(user.birthday)}</span>
              <span>Address: {UtilService.getFullAddress(user.street, user.city, user.state, user.zip)}</span>
            </>
          }
          btns={
            <Popable id="edit-about" title="About" trigger={<Btn label="Edit" icon="edit" size="medium" />}>
              <AboutForm
                userId={user.id}
                firstName={user.firstName}
                middleName={user.middleName}
                lastName={user.lastName}
                birthday={user.birthday}
                street={user.street}
                city={user.city}
                state={user.state}
                zip={user.zip}
              />
            </Popable>
          }
        />

        <AccountSettingItem
          title="Email address"
          info={<span className="email">{user.email}</span>}
          btns={
            <Popable id="edit-email" title="Edit email" trigger={<Btn label="Edit" icon="edit" size="medium" />}>
              <EmailForm userId={user.id} email={user.email} />
            </Popable>
          }
        />

        <AccountSettingItem
          title="Password"
          info={<span className="password">*********</span>}
          btns={
            <Popable id="edit-password" title="Edit password" trigger={<Btn label="Edit" icon="edit" size="medium" />}>
              <PasswordForm userId={user.id} />
            </Popable>
          }
        />
      </DashboardPanel>
    </DashboardSection>
  );
};
