import { useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { setNotification } from '../../../redux/actions/notificationAction';
import { setUser } from '../../../redux/actions/user.action';
import { AppState, getReduxStoreInstance } from '../../../redux/store';
import { UsersApi } from '../../../services/api/UsersApi';
import { Btn } from '../../inputs/Btn';

type PropsType = {
  clientSecret: string;
  paymentMethodId: string;
  onSuccess: () => void;
};

export const ThreeDSecureBtn = ({ clientSecret, paymentMethodId, onSuccess }: PropsType) => {
  const user = useSelector((state: AppState) => state.user);
  const store = getReduxStoreInstance();
  const stripe = useStripe();
  const [verifying, setVerifying] = useState(false);

  const handleConfirmPayment = async () => {
    if (!stripe) return;

    setVerifying(true);
    return stripe
      .confirmCardPayment(clientSecret, {
        payment_method: paymentMethodId,
      })
      .then(async (result) => {
        if (result.error) {
          setVerifying(false);
        } else {
          let counter = 0;
          let timeoutVar: NodeJS.Timeout;
          timeoutVar = setInterval(async () => {
            const { data } = await UsersApi.findOne(user.id);

            counter++;
            if (data.stripeMeta.paymentIntentStatus !== 'requires_action') {
              clearTimeout(timeoutVar);
              store.dispatch(setUser(data));
              setVerifying(false);
              onSuccess();
            } else if (data.stripeMeta.paymentIntentStatus === 'requires_action' && counter > 30) {
              store.dispatch(setNotification({ message: 'Verification timed out', type: 'error' }));
              clearTimeout(timeoutVar);
              setVerifying(false);
            }
          }, 1000);
        }
      })
      .catch((error) => {
        store.dispatch(setNotification({ message: 'There was an error', type: 'error' }));
        console.log(error);
        setVerifying(false);
      });
  };

  return <Btn label="Verify payment" size="large" hasArrow={true} color="green" onClick={handleConfirmPayment} loading={verifying} />;
};
