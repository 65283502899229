import { SmoothAnimate } from '../../../components/smooth-animate/SmoothAnimate';
import noResultsImage from '../../../img/icons/no-results-image.svg';
import { SearchForm } from './SearchForm';

type PropsType = {
  isVisible: boolean;
  fullName: string;
  place: string;
  handleSubmitSearch: (fullName: string, city: string, recaptchaToken: string) => void;
};

export const TryAgain = (props: PropsType) => {
  return (
    <SmoothAnimate isVisible={props.isVisible}>
      <img className="panel__icon" src={noResultsImage} alt="Nothing found" />
      <h1 className="panel__title">
        We couldn’t find any records for {props.fullName}, {props.place}
      </h1>
      <p className="panel__description">
        Please try again using your maiden name, searching for your previous location and make sure you are using your legal name (not a
        nickname).
      </p>
      <SearchForm handleSubmitSearch={props.handleSubmitSearch} />
    </SmoothAnimate>
  );
};
