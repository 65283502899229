import { NotificationActionTypes, REMOVE_NOTIFICATION, SET_NOTIFICATION } from '../actions/notificationAction';
import { NotificationStoreType } from '../types/notificationType';

const reducerInitialState: NotificationStoreType = [];

const notificationReducer = (state = reducerInitialState, action: NotificationActionTypes): NotificationStoreType => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return [...state, { ...action.notification, id: Date.now() }];
    case REMOVE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.id);
    default:
      return state;
  }
};

export { notificationReducer };
