type PropsType = {
  title: string;
  description?: React.ReactNode;
  children: React.ReactNode;
};

export const SimpleContent = (props: PropsType) => (
  <div className="simple-content">
    <h1 className="simple-content__title">{props.title}</h1>
    {props.description && <p className="simple-content__description">{props.description}</p>}
    <div className="simple-content__content">{props.children}</div>
  </div>
);
