import { Form, Formik } from 'formik';
import { Btn } from '../../components/inputs/Btn';
import { DateInput } from '../../components/inputs/DateInput';
import { FormControlRow } from '../../components/inputs/form-parts/FormControlRow';

export const DateFilterForm = (props: { from: string; to: string; onSubmit: (from: string, to: string) => Promise<void> }) => {
  return (
    <Formik
      initialValues={{ from: props.from, to: props.to }}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values.from, values.to);
        setSubmitting(false);
      }}
    >
      {({ errors, isSubmitting, values }) => (
        <Form>
          <FormControlRow className="search-params">
            <DateInput label="From" maxDate={new Date(values.to)} name="from" type="text" required placeholder="MM/DD/YYYY" />
            <DateInput label="To" maxDate={new Date()} name="to" type="text" required placeholder="MM/DD/YYYY" />
            <Btn type="submit" label="Filter" loading={isSubmitting} />
          </FormControlRow>
        </Form>
      )}
    </Formik>
  );
};
