import { useSelector } from 'react-redux';
import { ProfileForm } from '../../forms/profile/ProfileForm';
import { AppState } from '../../redux/store';
import { AddBtn } from '../inputs/AddBtn';
import { Popable } from '../popable/Popable';
import { ProfileCard } from './ProfileCard';

type PropsType = {
  allowAdd?: boolean;
};

export const ProfilesList = (props: PropsType) => {
  const user = useSelector((state: AppState) => state.user);
  const profiles = user.profiles;

  return (
    <div className="profiles-list">
      {profiles.map((profile) => (
        <ProfileCard key={profile.id} profile={profile} role="Member" />
      ))}
      {props.allowAdd && profiles.length < 5 && (
        <Popable id="new-profile" title="Add new profile" trigger={<AddBtn label="+ Add profile" />}>
          <ProfileForm userId={user.id} />
        </Popable>
      )}
    </div>
  );
};
