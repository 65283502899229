import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { ProfilesList } from '../../../../components/app-specific/ProfilesList';
import { DashboardContext } from '../../../../components/layout/dashboard-layout/DashboardLayout';
import { DashboardSection } from '../../../../components/layout/dashboard-layout/DashboardSection';
import { SeoService } from '../../../../services/SeoService';

export const ManageProfilesPage = () => {
  const dashboardContext = useContext(DashboardContext);
  dashboardContext.setDashboardLocation('Settings / Manage Profiles');

  return (
    <DashboardSection title="Manage Profiles">
      <Helmet>
        <title>{SeoService.getPageTitle('Manage Profiles')}</title>
      </Helmet>
      <p>Customize profiles you want us to monitor.</p>
      <ProfilesList allowAdd={true} />
    </DashboardSection>
  );
};
