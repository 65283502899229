type PropsType = {
  label: string;
  required?: boolean;
  error?: any;
  children: React.ReactNode;
};

export const FormSection = (props: PropsType) => {
  return (
    <div className="form-section">
      <div className="form-section__header">
        <span className="title">{props.label}</span>
        {typeof props.error === 'string' && <span className="error">{props.error}</span>}
      </div>
      <div className="form-section__content">{props.children}</div>
    </div>
  );
};
