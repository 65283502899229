import { DashboardSection } from '../../../../../components/layout/dashboard-layout/DashboardSection';
import { useSelector } from 'react-redux';
import { AppState, getReduxStoreInstance } from '../../../../../redux/store';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SubscriptionResponse, SubscriptionsApi } from '../../../../../services/api/SubscriptionsApi';
import { setStripeMeta } from '../../../../../redux/actions/user.action';
import { setNotification } from '../../../../../redux/actions/notificationAction';
import { Btn } from '../../../../../components/inputs/Btn';
import { UtilService } from '../../../../../services/Util.service';
import NumberFormat from 'react-number-format';
import { BriefCardInfo } from '../../../../../components/app-specific/card/BriefCardInfo';
import { Popable } from '../../../../../components/popable/Popable';
import classNames from 'classnames';
import { DashboardPanel } from '../../../../../components/layout/dashboard-layout/DashboardPanel';
import { SITEMAP } from '../../../../../config/SITEMAP';
import { CreditCardForm } from '../../../../../forms/credit-card/CreditCardForm';
import { MessageLayout } from '../../../../../components/popable/MessageLayout';
import { InfoRow } from '../../../../../components/inputs/form-parts/InfoRow';
import { ThreeDSecureBtn } from '../../../../../components/app-specific/3dsecure/ThreeDSecureBtn';

export const YourPlan = () => {
  const user = useSelector((state: AppState) => state.user);
  const stripeMeta = useSelector((state: AppState) => state.user.stripeMeta);
  const store = getReduxStoreInstance();
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [isRemoveCardLoading, setRemoveCardLoading] = useState(false);
  const [isCancelLoading, setCancelLoading] = useState(false);
  const [subscription, setSubscription] = useState<SubscriptionResponse>();

  useEffect(() => {
    loadSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelSubscription = async () => {
    setCancelLoading(true);
    try {
      const res = await SubscriptionsApi.cancelSubscription(user.id);
      store.dispatch(setStripeMeta(res.data));
      loadSubscription();
      store.dispatch(setNotification({ message: 'Subscription successfully canceled', type: 'success' }));
    } catch (error) {
      store.dispatch(setNotification({ message: 'Could not cancel subscription', type: 'error' }));
      setCancelLoading(false);
    }
  };

  const handleRemovePaymentMethod = async () => {
    setRemoveCardLoading(true);
    try {
      const res = await SubscriptionsApi.removePaymentMethod(user.id);
      store.dispatch(setStripeMeta(res.data));
      store.dispatch(setNotification({ message: 'Payment method successfully removed', type: 'success' }));
      loadSubscription();
    } catch (error) {
      store.dispatch(setNotification({ message: 'Could not remove payment method', type: 'error' }));
    }
    setRemoveCardLoading(false);
  };

  const loadSubscription = async () => {
    setLoading(true);
    try {
      const res = await SubscriptionsApi.getCurrentSubscription(user.id);
      setSubscription(res.data);
    } catch (error) {
      if (error.statusCode !== 404 && error.message !== 'Subscription not active')
        store.dispatch(setNotification({ message: 'Could not load subscription', type: 'error' }));
      setSubscription(undefined);
    }
    setLoading(false);
  };

  /**
   * Card error
   */
  let activeNotification;
  if (stripeMeta.paymentIntentStatus === 'requires_payment_method' && !stripeMeta.clientSecret) {
    activeNotification = <InfoRow type="error" message="Payment failed due to payment method error. Please replace your payment method." />;
  }

  /**
   * No client secret error (BUG)
   */
  let noClientSecret;
  if (stripeMeta.paymentIntentStatus === 'requires_action' && !stripeMeta.clientSecret && stripeMeta.paymentMethodId) {
    noClientSecret = (
      <InfoRow type="error" message="Payment failed due to authentication. Please contact us on support@allenprivacy.com." />
    );
  }

  /**
   * No-Plan row
   */
  let noPlanRow;
  if (
    subscription?.status !== 'active' &&
    stripeMeta.paymentIntentStatus !== 'requires_action' &&
    stripeMeta.paymentIntentStatus !== 'requires_payment_method'
  ) {
    noPlanRow = (
      <div className="panel-row panel-row--no-plan">
        <span>You don't have any active subscription plans.</span>
        <Btn label="Choose a plan" size="medium" color="green" onClick={() => history.push(SITEMAP.CUSTOMIZE_PLAN)} />
      </div>
    );
  }

  /**
   * Requires action row - 3D Secure
   */
  let requiresActionRow;
  if (stripeMeta.subscriptionStatus !== 'active' && stripeMeta.clientSecret && stripeMeta.paymentMethodId) {
    activeNotification = <InfoRow type="error" message="Payment failed due authentication." />;
    requiresActionRow = (
      <div className="panel-row panel-row--action">
        <span>Action required by your credit card provider.</span>
        <ThreeDSecureBtn clientSecret={stripeMeta.clientSecret} paymentMethodId={stripeMeta.paymentMethodId} onSuccess={loadSubscription} />
      </div>
    );
  }

  /**
   * Main row
   */
  let mainRow;
  if (stripeMeta.paymentMethodId || subscription?.status === 'active') {
    // Show current plan, IF it is active
    if (subscription && subscription.status === 'active') {
      mainRow = (
        <div className="active-plan">
          <span className="monitoring">Monitoring {subscription?.productDescription}</span>
          <span className="next-payment">
            Next recurring payment is on {UtilService.unixToReadableDateFormat(subscription.nextPaymentDate)}
          </span>

          <Popable
            id="cancel-subscription"
            title="Cancel subscription"
            trigger={<Btn label="Cancel subscription" color="red" size="extra-small" />}
          >
            <MessageLayout
              controls={
                <Btn
                  color="red"
                  size="medium"
                  label="Yes, cancel subscription"
                  loading={isCancelLoading}
                  onClick={handleCancelSubscription}
                />
              }
            >
              <p>
                If you cancel your subscription, all monitoring and removal activities will be <b>stopped without any refunds.</b>
              </p>
              <p>Are you sure you want to cancel your subscription?</p>
            </MessageLayout>
          </Popable>
        </div>
      );
    }

    // Show payment method controls, if payment method exists
    if (stripeMeta.paymentMethodId) {
      mainRow = (
        <>
          {mainRow}
          <BriefCardInfo
            cardBrand={stripeMeta.cardBrand!}
            last4={stripeMeta.last4!}
            activePlan={
              subscription?.status === 'active' && (
                <span>
                  {'Subscription plan: '}
                  <NumberFormat value={subscription?.price! / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} />/
                  {subscription?.isAnnually === true ? 'year' : 'mo'}
                </span>
              )
            }
          />
          <div className="card-controls">
            <Popable id="replace-card" title="Replace card" trigger={<Btn label="Replace" color="blue" size="small" />}>
              <CreditCardForm replace={true} />
            </Popable>

            <Btn label="Remove" color="red" size="small" onClick={handleRemovePaymentMethod} loading={isRemoveCardLoading} />
          </div>
        </>
      );
    }
    // Show add new payment method control
    else {
      mainRow = (
        <>
          {mainRow}
          <div className="card-controls">
            <Popable id="add-card" title="Add new credit card" trigger={<Btn label="Add credit card" color="blue" size="medium" />}>
              <CreditCardForm replace={true} />
            </Popable>
          </div>
        </>
      );
    }

    // Add wrapper
    mainRow = (
      <div
        className={classNames({
          'panel-row': true,
          'panel-row--card': subscription?.status !== 'active',
          'panel-row--card-and-plan': subscription?.status === 'active',
        })}
      >
        {mainRow}
      </div>
    );
  }

  return (
    <DashboardSection title="Your Plan">
      {activeNotification}
      {noClientSecret}
      <DashboardPanel className={classNames({ 'active-card-panel': true })} loading={isLoading}>
        {noPlanRow}
        {mainRow}
        {requiresActionRow}
      </DashboardPanel>
    </DashboardSection>
  );
};
