import * as Yup from 'yup';
import { Formik, Form, FieldArray } from 'formik';
import { FormControlRow } from '../../components/inputs/form-parts/FormControlRow';
import { TextInput } from '../../components/inputs/TextInput';
import { FormSection } from '../../components/inputs/form-parts/FormSection';
import { DateInput } from '../../components/inputs/DateInput';
import { FormDynamicRow } from '../../components/inputs/form-parts/FormDynamicRow';
import { DynamicDeleteBtn } from '../../components/inputs/form-parts/DynamicDeleteBtn';
import { PlaceInput } from '../../components/inputs/PlaceInput';
import { FormAddBtn } from '../../components/inputs/form-parts/FormAddBtn';
import { FormSubmitRow } from '../../components/inputs/form-parts/FormSubmitRow';
import { Btn } from '../../components/inputs/Btn';
import { ProfileResponse, ProfilesApi } from '../../services/api/ProfilesApi';
import { useContext, useState } from 'react';
import classNames from 'classnames';
import { PopupContext } from '../../components/popable/Popable';
import { AddressType } from '../../types/address.type';
import { UtilService } from '../../services/Util.service';
import {
  BirthdayValidator,
  EmailValidator,
  FirstNameValidator,
  LastNameValidator,
  PhoneNumberValidator,
  UniqueValidatorCb,
} from '../../services/validators/YupValidators';
import { AppState, getReduxStoreInstance } from '../../redux/store';
import { useSelector } from 'react-redux';
import { PricePreviewResponse, SubscriptionsApi } from '../../services/api/SubscriptionsApi';
import { addProfile, removeProfile, setStripeMeta, updateProfile } from '../../redux/actions/user.action';
import { MessageLayout } from '../../components/popable/MessageLayout';
import { setNotification } from '../../redux/actions/notificationAction';

// TODO - SPLIT THESE PROPS!
type PropsType = {
  profileId?: string;
  userId: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  birthday?: string;
  emails?: string[];
  phones?: string[];
  addresses?: AddressType[];
  onCreateResult?: (profile: ProfileResponse) => void;
  onUpdateResult?: (profile: ProfileResponse) => void;
  onDeleteResult?: (profileId: string) => void;
};

export const ProfileForm = (props: PropsType) => {
  const store = getReduxStoreInstance();
  const [pricePreview, setPricePreview] = useState<PricePreviewResponse | undefined>(undefined);
  const [isDeleting, setDeleting] = useState(false);
  const [planUpgradeScreen, setPlanUpgradeScreen] = useState(false);
  const [planDowngradeScreen, setPlanDowngradeScreen] = useState(false);
  const user = useSelector((state: AppState) => state.user);

  const handleDeleteProfile = () => {
    // First attempt to delete profile
    if (user.stripeMeta?.subscriptionId && planDowngradeScreen === false) {
      setPlanDowngradeScreen(true);
      return;
    }

    // Second attempt
    if (props.profileId) {
      setDeleting(true);
      ProfilesApi.delete(props.userId, props.profileId)
        .then((res) => {
          store.dispatch(setStripeMeta(res.data));
          store.dispatch(setNotification({ message: 'Profile successfully deleted', type: 'success' }));
          if (props.profileId) store.dispatch(removeProfile(props.profileId));
          setDeleting(false);
          popupContext.closePopup();
        })
        .catch((error) => {
          console.log(error);
          store.dispatch(setNotification({ message: 'Could not delete profile', type: 'error' }));
          setDeleting(false);
        });
    }
  };

  const popupContext = useContext(PopupContext);
  const combinedAddresses = UtilService.combineAddressArray(props.addresses || []);

  Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a: any) => a) {
    return this.test('unique', message, function (list: any) {
      return list.length === new Set(list.map(mapper)).size;
    });
  });

  return (
    <Formik
      initialValues={{
        firstName: props.firstName || '',
        middleName: props.middleName || '',
        lastName: props.lastName || '',
        birthday: props.birthday || '',
        emails: props.emails || [''],
        phones: props.phones || [''],
        addresses: combinedAddresses.length > 0 ? combinedAddresses : [{ street: '', place: '', zip: '' }],
      }}
      validationSchema={Yup.object({
        firstName: FirstNameValidator,
        lastName: LastNameValidator,
        birthday: BirthdayValidator,
        emails: Yup.array().test('Unique', 'Values need te be unique', UniqueValidatorCb).of(EmailValidator),
        phones: Yup.array().of(PhoneNumberValidator),
      }).shape({
        addresses: Yup.array()
          .of(
            Yup.object().shape({
              street: Yup.string().required('Required'),
              place: Yup.string().required('Required'),
              zip: Yup.string().required('Required'),
            }),
          )
          .min(1, 'At least 1 address is mandatory'),
      })}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        const addresses = UtilService.splitAdressArray(values.addresses);
        const params = { ...values, birthday: values.birthday ? values.birthday : undefined, addresses };

        if (user.stripeMeta?.subscriptionId && !props.profileId && planUpgradeScreen === false) {
          // User is trying to UPGRADE the plan - adding new profile
          setPlanUpgradeScreen(true);
          SubscriptionsApi.getPricePreview(user.id, {
            numberOfProfiles: user.profiles.length + 1,
            annual: false,
          }).then(({ data }) => {
            setPricePreview(data);
          });

          setSubmitting(false);
        } else {
          // User if just creating or editing existing profiles without any active subscription
          if (!props.profileId) {
            // Create new profile
            ProfilesApi.create(props.userId, params)
              .then((res) => {
                store.dispatch(addProfile(res.data));
                popupContext.closePopup();
                store.dispatch(setNotification({ message: 'Profile successfully created', type: 'success' }));
                setSubmitting(false);
              })
              .catch((error) => {
                if (error.message[0].property === 'birthday') setErrors({ birthday: 'Date format not valid' });
                console.log(error);
                store.dispatch(setNotification({ message: 'Could not create profile', type: 'error' }));
                setSubmitting(false);
              });
          } else {
            // Update existing profile
            ProfilesApi.update(props.userId, props.profileId, params)
              .then((res) => {
                store.dispatch(updateProfile(res.data));
                popupContext.closePopup();
                store.dispatch(setNotification({ message: 'Profile successfully updated', type: 'success' }));
                setSubmitting(false);
              })
              .catch((error) => {
                console.log(error);
                store.dispatch(setNotification({ message: 'Could not update profile', type: 'error' }));
                setSubmitting(false);
              });
          }
        }
      }}
    >
      {({ values, errors, isSubmitting, submitForm }) => {
        if (planUpgradeScreen) {
          return (
            <MessageLayout
              loading={!pricePreview}
              controls={
                <Btn color="green" size="medium" icon="purchase" label="Purchase" loading={isSubmitting} onClick={() => submitForm()} />
              }
            >
              {pricePreview && (
                <>
                  <p>
                    You’re about to upgrade your plan with 1 more profile. The price is calculated based on the number of days left in the
                    current billing period.
                  </p>
                  <p className="total">Total: {UtilService.toDollars(pricePreview.nextInvoicePrice)}</p>
                  <p>New subscription price: {UtilService.toDollars(pricePreview.regularPrice)}</p>
                </>
              )}
            </MessageLayout>
          );
        } else if (planDowngradeScreen) {
          return (
            <MessageLayout
              controls={<Btn color="red" size="medium" label="Yes, delete profile" onClick={handleDeleteProfile} loading={isDeleting} />}
            >
              <p>
                You’re about to delete a profile and your subscription plan will be downgraded <b>without any refunds</b>. All monitoring
                and removal actions will be stopped immediately.
              </p>
              <p>Are you sure that you want to delete a profile?</p>
            </MessageLayout>
          );
        }
        return (
          <Form className={classNames({ 'profile-form': true, 'profile-form--edit': props.profileId })}>
            <FormControlRow>
              <TextInput label="First Name" name="firstName" type="text" placeholder="e.g. John" autoComplete="given-name" required />
              <TextInput label="Middle Name" name="middleName" type="text" placeholder="e.g. Steve" autoComplete="additional-name" />
              <TextInput label="Last Name" name="lastName" type="text" required placeholder="e.g. Smith" autoComplete="family-name" />
            </FormControlRow>
            <FormControlRow>
              <DateInput label="Date of Birth" name="birthday" type="text" placeholder="MM/DD/YYYY" limitYears={13} />
            </FormControlRow>
            <FormSection label="Emails">
              <FieldArray
                name="emails"
                render={(arrayHelpers) => (
                  <div>
                    {values.emails &&
                      values.emails.length > 0 &&
                      values.emails.map((friend, index) => (
                        <FormDynamicRow key={index} controls={<DynamicDeleteBtn onClick={() => arrayHelpers.remove(index)} />}>
                          <TextInput label="Email" name={`emails.${index}`} type="text" placeholder="e.g. 123456789" />
                        </FormDynamicRow>
                      ))}
                    <FormAddBtn label="Add email" onClick={() => arrayHelpers.push('')} />
                  </div>
                )}
              />
            </FormSection>
            <FormSection label="Addresses" error={errors.addresses} required>
              <FieldArray
                name="addresses"
                render={(arrayHelpers) => (
                  <div>
                    {values.addresses &&
                      values.addresses.length > 0 &&
                      values.addresses.map((address, index) => (
                        <FormDynamicRow key={index} controls={<DynamicDeleteBtn onClick={() => arrayHelpers.remove(index)} />}>
                          <TextInput
                            label="Street Address"
                            name={`addresses[${index}].street`}
                            type="text"
                            placeholder="e.g. Allen Street 19"
                            required
                          />
                          <PlaceInput label="City" name={`addresses[${index}].place`} placeholder="e.g. Boston, MA" required />
                          <TextInput label="ZIP" name={`addresses[${index}].zip`} type="text" placeholder="e.g. 02101" required />
                        </FormDynamicRow>
                      ))}
                    <FormAddBtn label="Add an address" onClick={() => arrayHelpers.push('')} />
                  </div>
                )}
              />
            </FormSection>
            <FormSection label="Phone numbers">
              <FieldArray
                name="phones"
                render={(arrayHelpers) => (
                  <div>
                    {values.phones &&
                      values.phones.length > 0 &&
                      values.phones.map((friend, index) => (
                        <FormDynamicRow key={index} controls={<DynamicDeleteBtn onClick={() => arrayHelpers.remove(index)} />}>
                          <TextInput label="Phone number" name={`phones.${index}`} type="text" placeholder="e.g. 123456789" />
                        </FormDynamicRow>
                      ))}
                    <FormAddBtn label="Add a phone" onClick={() => arrayHelpers.push('')} />
                  </div>
                )}
              />
            </FormSection>
            <FormSubmitRow>
              {props.profileId && (
                <Btn
                  type="button"
                  onClick={handleDeleteProfile}
                  fill="outline"
                  label="Delete"
                  color="red"
                  size="small"
                  loading={isDeleting}
                />
              )}
              <Btn
                type="submit"
                label={props.profileId ? 'Save' : 'Add'}
                icon={props.profileId ? 'save' : 'plus'}
                color="green"
                size="small"
                loading={isSubmitting}
              />
            </FormSubmitRow>
          </Form>
        );
      }}
    </Formik>
  );
};
