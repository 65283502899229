import { AddressType } from '../../types/address.type';
import { AuthService } from '../Auth.service';
import { get, post, put, destroy } from './config';
import { StripeMetaResponse } from './UsersApi';

type CreateProfileRequest = {
  firstName: string;
  middleName?: string;
  lastName: string;
  emails?: string[];
  birthday?: string;
  phones?: string[];
  addresses: AddressType[];
};

export type ProfileResponse = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthday: string;
  emails: string[];
  phones: string[];
  addresses: AddressType[];
  active: boolean;
  onerepProfileId: number;
};

export type RecordResponse = {
  dataBroker: string;
  profile: string;
  location: string;
  phone: string;
  url: string;
  status: 'new' | 'optout_in_progress' | 'waiting_for_verification' | 'removed';
};

export type RecordsResponse = {
  new: RecordResponse[];
  optout_in_progress: RecordResponse[];
  removed: RecordResponse[];
};

export class ProfilesApi {
  static create = async (userId: string, params: CreateProfileRequest) =>
    await post<ProfileResponse>(`users/${userId}/profiles`, params, AuthService.getAuthHeader());

  static findAll = async (userId: string) => await get<ProfileResponse[]>(`users/${userId}/profiles`, AuthService.getAuthHeader());

  static getRecords = async (userId: string, profileId: string) =>
    await get<RecordsResponse>(`users/${userId}/profiles/${profileId}/records`, AuthService.getAuthHeader());

  static getAllUserRecords = async (userId: string) =>
    await get<RecordsResponse>(`users/${userId}/profiles/records`, AuthService.getAuthHeader());

  static update = async (userId: string, profileId: string, params: CreateProfileRequest) =>
    await put<ProfileResponse>(`users/${userId}/profiles/${profileId}`, params, AuthService.getAuthHeader());

  static delete = async (userId: string, profileId: string) =>
    await destroy<StripeMetaResponse>(`users/${userId}/profiles/${profileId}`, AuthService.getAuthHeader());
}
