import { Notification } from '../types/notificationType';

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export interface SetNotification {
  type: typeof SET_NOTIFICATION;
  notification: Notification;
}

export interface RemoveNotification {
  type: typeof REMOVE_NOTIFICATION;
  id: number;
}

export type NotificationActionTypes = SetNotification | RemoveNotification;

export const setNotification = (notification: Notification): NotificationActionTypes => ({
  type: SET_NOTIFICATION,
  notification,
});

export const removeNotification = (id: number): NotificationActionTypes => ({
  type: REMOVE_NOTIFICATION,
  id,
});
