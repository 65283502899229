import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Btn } from '../../../components/inputs/Btn';
import { FormControlRow } from '../../../components/inputs/form-parts/FormControlRow';
import { TextInput } from '../../../components/inputs/TextInput';
import { DashboardPanel } from '../../../components/layout/dashboard-layout/DashboardPanel';

type PropsType = {
  onSubmit: (promoCode: string) => void;
  isLoading: boolean;
};

export const PromotionCodePanel = (props: PropsType) => {
  return (
    <DashboardPanel wrapperClassName="promo-code-wrapper">
      <div className="panel-row">
        <Formik
          initialValues={{ promotionCode: '' }}
          validationSchema={Yup.object({
            promotionCode: Yup.string().required('Promotion code is required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            props.onSubmit(values.promotionCode);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="scan-form">
              <FormControlRow>
                <TextInput label="Promotion code" name="promotionCode" type="text" required />
                <Btn type="submit" size="medium" label="Redeem" hasArrow={true} loading={props.isLoading} />
              </FormControlRow>
            </Form>
          )}
        </Formik>
      </div>
    </DashboardPanel>
  );
};
