type PropsType = {
  children: React.ReactNode;
  controls: React.ReactNode;
};

export const FormDynamicRow = (props: PropsType) => {
  return (
    <div className="dynamic-row">
      <div className="dynamic-row__content">{props.children}</div>
      <div className="dynamic-row__controls">{props.controls}</div>
    </div>
  );
};
