import { useSelector } from 'react-redux';
import { AppState, getReduxStoreInstance } from '../../../redux/store';
import { SubscriptionsApi } from '../../../services/api/SubscriptionsApi';
import { useState } from 'react';
import { setStripeMeta } from '../../../redux/actions/user.action';
import { useHistory } from 'react-router-dom';
import { SITEMAP } from '../../../config/SITEMAP';
import { DashboardPanel } from '../../../components/layout/dashboard-layout/DashboardPanel';
import { BriefCardInfo } from '../../../components/app-specific/card/BriefCardInfo';
import { Popable } from '../../../components/popable/Popable';
import { Btn } from '../../../components/inputs/Btn';
import { CreditCardForm } from '../../../forms/credit-card/CreditCardForm';
import { setNotification } from '../../../redux/actions/notificationAction';
import { ThreeDSecureBtn } from '../../../components/app-specific/3dsecure/ThreeDSecureBtn';

type PropsType = {
  isAnnually: boolean;
  promotionCode?: string;
  amount?: number;
};

export const CheckoutPanel = (props: PropsType) => {
  const user = useSelector((state: AppState) => state.user);
  const stripeMeta = user.stripeMeta;
  const [isLoading, setLoading] = useState(false);
  const store = getReduxStoreInstance();
  const history = useHistory();

  const handleUpdatePaymentMethod = async (paymentMethodId: string) => {
    setLoading(true);
    try {
      await SubscriptionsApi.updatePaymentMethod(user.id, { paymentMethodId });
      await handleCreateSubscription();
    } catch (error) {
      store.dispatch(setNotification({ message: 'Could not set payment method', type: 'error' }));
      console.log(error);
    }
    setLoading(false);
  };

  const handleCreateSubscription = async () => {
    setLoading(true);

    try {
      const fbqInReact = (window as any).fbq;
      if (fbqInReact) {
        fbqInReact('track', 'Purchase', { currency: 'USD', value: props.amount ? props.amount / 100 : 19.99 });
      }
    } catch (error) {
      console.log('FB Pixel Track failed');
    }

    try {
      const res = await SubscriptionsApi.createSubscription(user.id, { isAnually: props.isAnnually, promotionCode: props.promotionCode });
      if (res.data.paymentIntentStatus === 'requires_action') {
        // 3D Secure
        store.dispatch(setNotification({ message: 'Card authorization required', type: 'error' }));
        store.dispatch(setStripeMeta(res.data));
      } else if (res.data.paymentIntentStatus === 'succeeded' || res.data.paymentIntentStatus === null) {
        // All good
        store.dispatch(setNotification({ message: 'Subscription successfully started', type: 'success' }));
        store.dispatch(setStripeMeta(res.data));
        history.push(SITEMAP.DASHBOARD);
      } else {
        // Some other error
        store.dispatch(setStripeMeta(res.data));
        history.push(SITEMAP.DASHBOARD);
        store.dispatch(setNotification({ message: 'Could not create new subscription', type: 'error' }));
      }
    } catch (error) {
      if (error.statusCode === 400) {
        store.dispatch(setNotification({ message: error.message, type: 'error' }));
      } else {
        store.dispatch(setNotification({ message: 'Could not create new subscription', type: 'error' }));
      }
      console.log(error);
    }
    setLoading(false);
  };

  const handleSuccess = () => {
    store.dispatch(setNotification({ message: 'Subscription successfully started', type: 'success' }));
    history.push(SITEMAP.DASHBOARD);
  };

  const is3DSecure = stripeMeta.paymentMethodId && stripeMeta.clientSecret && stripeMeta.paymentIntentStatus === 'requires_action';

  return (
    <DashboardPanel>
      {stripeMeta.paymentMethodId ? (
        <div className="panel-row panel-row--checkout">
          <BriefCardInfo cardBrand={stripeMeta.cardBrand!} last4={stripeMeta.last4!} />
          <div className="checkout-options">
            <Popable id="replace-card" title="Replace card" trigger={<Btn label="Replace card" color="blue" size="medium" />}>
              <CreditCardForm replace={true} />
            </Popable>
            {!is3DSecure && (
              <Btn label="Purchase" color="green" size="medium" icon="purchase" onClick={handleCreateSubscription} loading={isLoading} />
            )}
          </div>
        </div>
      ) : (
        <div className="panel-row panel-row--checkout">
          <CreditCardForm onCreatePaymentMethod={handleUpdatePaymentMethod} loading={isLoading} />
        </div>
      )}
      {is3DSecure && (
        <div className="panel-row panel-row--action">
          <span>Action required by your credit card provider.</span>
          <ThreeDSecureBtn
            clientSecret={user.stripeMeta.clientSecret!}
            paymentMethodId={user.stripeMeta.paymentMethodId!}
            onSuccess={handleSuccess}
          />
        </div>
      )}
    </DashboardPanel>
  );
};
