import classNames from 'classnames';
import { useField } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FormikRichInputType } from './text-input-parts/FormikRichInput.type';
import { TextInputError } from './text-input-parts/TextInputError';
import { TextInputLabel } from './text-input-parts/TextInputLabel';
import { TextInputWrapper } from './text-input-parts/TextInputWrapper';

export const DateInput = (props: FormikRichInputType & { maxDate?: Date; limitYears?: number }) => {
  const [field, meta, helper] = useField(props);
  const showError = meta.touched && meta.error;

  const handleChange = (date: Date) => {
    if (date && new Date(date)) helper.setValue(date.toISOString());
    else helper.setValue('');
  };

  let maxDate;
  if (props.maxDate) {
    maxDate = props.maxDate;
  } else if (props.limitYears) {
    maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - props.limitYears);
  }

  return (
    <TextInputWrapper id={props.id || props.name}>
      <TextInputLabel for={props.id || props.name} label={props.label} required={props.required} />
      <DatePicker
        id={props.id || props.name}
        disabledKeyboardNavigation
        maxDate={maxDate}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(date: Date) => handleChange(date)}
        className={classNames({ error: showError, datePicker: true })}
        autoComplete="off"
        placeholderText="MM/DD/YYYY"
        onBlur={field.onBlur}
      />
      <TextInputError showError={!!showError} error={meta.error} />
    </TextInputWrapper>
  );
};
