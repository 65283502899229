import classNames from 'classnames';
import { FullDivLoaderWrapper } from '../../utils/full-div-loader/FullDivLoaderWrapper';
import { Help } from '../../utils/help/Help';

type PropsType = {
  children: React.ReactNode;
  className?: string;
  wrapperClassName?: string;
  help?: string;
  loading?: boolean;
};
export const DashboardPanel = (props: PropsType) => (
  <div className={`dashboard-panel-wrapper ${props.wrapperClassName}`}>
    <FullDivLoaderWrapper loading={props.loading || false} />
    <div className={classNames({ 'dashboard-panel': true, [props.className || '']: props.className })}>
      {props.help && <Help align="left" message={props.help} />}
      {props.children}
    </div>
  </div>
);
