import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { TextInput } from '../../components/inputs/TextInput';
import { Btn } from '../../components/inputs/Btn';
import { SimpleLayout } from '../../components/layout/simple-layout/SimpleLayout';
import { PlaceInput } from '../../components/inputs/PlaceInput';
import { CheckboxInput } from '../../components/inputs/CheckboxInput';
import { DateInput } from '../../components/inputs/DateInput';
import { FormControlRow } from '../../components/inputs/form-parts/FormControlRow';
import { FormSubmitRow } from '../../components/inputs/form-parts/FormSubmitRow';
import { PasswordCheckup, validatePassword } from '../../components/inputs/PasswordCheckup';
import { UsersApi } from '../../services/api/UsersApi';
import { AuthService } from '../../services/Auth.service';
import { useHistory } from 'react-router-dom';
import { SITEMAP } from '../../config/SITEMAP';
import { InfoRow } from '../../components/inputs/form-parts/InfoRow';
import {
  BirthdayValidator,
  EmailValidator,
  FirstNameValidator,
  LastNameValidator,
  PlaceValidator,
  StreetValidator,
  ZipValidator,
} from '../../services/validators/YupValidators';
import { Helmet } from 'react-helmet';
import { SeoService } from '../../services/SeoService';

export const RegisterPage = () => {
  const [formError, setFormError] = useState('');

  const history = useHistory();

  return (
    <SimpleLayout>
      <Helmet>
        <title>{SeoService.getPageTitle('Register')}</title>
      </Helmet>
      <div className="panel panel--form">
        <h1 className="panel__title">Let's create your account</h1>
        <p className="panel__description">
          Please specify correct information so we can make sure our monitoring tools are doing an accurate search and remove the right
          records.
        </p>
        <Formik
          initialErrors={{ password: '0123' }}
          initialValues={{
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            birthday: '',
            street: '',
            place: '',
            zip: '',
            password: '',
            passwordConfirmation: '',
            termsAndPrivacy: false,
          }}
          validate={validatePassword}
          validationSchema={Yup.object({
            firstName: FirstNameValidator,
            lastName: LastNameValidator,
            email: EmailValidator,
            birthday: BirthdayValidator.required('Birthday is mandatory'),
            street: StreetValidator,
            place: PlaceValidator,
            zip: ZipValidator,
            termsAndPrivacy: Yup.boolean().oneOf([true]),
          })}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            const place = values.place.split(', ');
            const city = place[0];
            const state = place[1];

            let res;
            try {
              res = await UsersApi.create({ ...values, city, state });
              AuthService.login(res.data.access_token, res.data.user);

              history.push(SITEMAP.CUSTOMIZE_PLAN);
            } catch (error) {
              if (error.statusCode === 400) setErrors({ email: 'Email is already registered' });
              else setFormError('There was an unexpected error. Please contact support.');
            }

            setSubmitting(false);
          }}
        >
          {({ errors, isSubmitting }) => {
            return (
              <Form className="register-form">
                <FormControlRow>
                  <TextInput label="First Name" name="firstName" type="text" placeholder="e.g. John" autoComplete="given-name" required />
                  <TextInput label="Middle Name" name="middleName" type="text" placeholder="e.g. Steve" autoComplete="additional-name" />
                  <TextInput label="Last Name" name="lastName" type="text" required placeholder="e.g. Smith" autoComplete="family-name" />
                </FormControlRow>
                <FormControlRow>
                  <TextInput label="E-mail address" name="email" type="email" required placeholder="john@gmail.com" />
                  <DateInput label="Date of Birth" name="birthday" type="text" required placeholder="MM/DD/YYYY" limitYears={13} />
                </FormControlRow>
                <FormControlRow>
                  <TextInput
                    label="Street Address"
                    name="street"
                    type="text"
                    placeholder="e.g. Allen Street 19"
                    autoComplete="street-address"
                    required
                  />
                  <PlaceInput label="City" name="place" placeholder="e.g. Boston, MA" autoComplete="avoid-chrome-autocomplete" required />
                  <TextInput label="ZIP" name="zip" type="text" placeholder="e.g. 02101" autoComplete="postal-code" required />
                </FormControlRow>
                <FormControlRow>
                  <TextInput label="Password" name="password" type="password" autoComplete="new-password" hideErrorLabel={true} required />
                  <TextInput label="Repeat Password" name="passwordConfirmation" type="password" hideErrorLabel={true} required />
                </FormControlRow>
                <PasswordCheckup passwordErrors={errors.password} />
                <FormControlRow>
                  <CheckboxInput
                    label={
                      <>
                        I have read and agree with the{' '}
                        <a href={SITEMAP.TERMS_OF_USE} target="_blank" rel="noreferrer">
                          Terms of Service
                        </a>{' '}
                        and{' '}
                        <a href={SITEMAP.PRIVACY_POLICY} target="_blank" rel="noreferrer">
                          Privacy Policy
                        </a>
                        .
                      </>
                    }
                    name="termsAndPrivacy"
                    required
                  />
                </FormControlRow>
                {formError && <InfoRow type="error" message={formError} />}
                <FormSubmitRow>
                  <Btn type="submit" label="Register" hasArrow={true} loading={isSubmitting} />
                </FormSubmitRow>
              </Form>
            );
          }}
        </Formik>
      </div>
    </SimpleLayout>
  );
};
