import logoPortal from '../../../img/logo/logo-portal.svg';
import logoSymbolWhite from '../../../img/logo/logo-symbol-white.svg';
import arrow from '../../../img/dashboard/user-dropdown-arrow.svg';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { AuthService } from '../../../services/Auth.service';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/store';
import { SITEMAP } from '../../../config/SITEMAP';
import { SidebarNav } from './SidebarNav';
import { UtilService } from '../../../services/Util.service';
import { NotificationBarPlan } from './NotificationBarPlan';
import { NotificationBarEmail } from './NotificationBarEmail';
import React from 'react';
import { HubspotService } from '../../../services/HubspotService';

type DashboardLayoutProps = {
  children: React.ReactNode;
};

export const DashboardContext = React.createContext({ setDashboardLocation: (path: string) => {} });

export const DashboardLayout = (props: DashboardLayoutProps) => {
  const user = useSelector((state: AppState) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const wrapperRef = useRef(null);
  const transitionRef = useRef(null);

  const { pathname } = useLocation();

  useEffect(() => {
    HubspotService.hideHubspotChat();
  }, []);

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname]);

  const setDashboardLocation = (path: string) => {
    setCurrentPath(path);
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !(wrapperRef as any).current.contains(event.target)) setIsOpen(false);
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <DashboardContext.Provider value={{ setDashboardLocation: setDashboardLocation }}>
      <div className="dashboard-layout">
        <div className="dashboard-layout__sidebar">
          <div className="dashboard-layout__sidebar__logo">
            <Link to={SITEMAP.DASHBOARD}>
              <img src={logoPortal} alt="AP Portal logo" className="logo-portal" />
              <img src={logoSymbolWhite} alt="AP Portal logo" className="logo-symbol" />
            </Link>
            <button className={classNames({ hamburger: true, open: isMenuOpen })} onClick={() => setMenuOpen(!isMenuOpen)}></button>
          </div>
          <SidebarNav isOpen={isMenuOpen} />
        </div>
        <div className="dashboard-layout__content">
          {(!user.stripeMeta || user.stripeMeta.subscriptionStatus !== 'active') && <NotificationBarPlan />}
          {!user.activated && <NotificationBarEmail />}
          <header className="dashboard-header">
            <div className="site-location">{currentPath}</div>
            <div ref={wrapperRef} className={classNames({ 'user-profile': true, 'user-profile--open': isOpen })}>
              <button
                className="toggle-dropdown"
                onClick={(e) => {
                  setIsOpen(!isOpen);
                }}
              >
                <span>{UtilService.getFullName(user.firstName, user.middleName, user.lastName)}</span>
                <img src={arrow} className="arrow" alt="Open dropdown arrow" />
              </button>
              <CSSTransition nodeRef={transitionRef} in={isOpen} timeout={200} classNames="user-profile__dropdown" unmountOnExit>
                <ul ref={transitionRef} className="user-profile__dropdown">
                  <li>
                    <Link to={SITEMAP.MANAGE_PROFILES} onClick={(e) => setIsOpen(false)}>
                      Manage Profiles
                    </Link>
                  </li>
                  <li>
                    <Link to={SITEMAP.ACCOUNT} onClick={(e) => setIsOpen(false)}>
                      Account
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setIsOpen(false);
                        HubspotService.showHubspotChat();
                      }}
                    >
                      Chat with us
                    </button>
                  </li>
                  <li>
                    <a href={SITEMAP.TERMS_OF_USE} target="_blank" rel="noreferrer" onClick={(e) => setIsOpen(false)}>
                      Terms of Use
                    </a>
                  </li>
                  <li>
                    <button
                      onClick={(e) => {
                        AuthService.logout();
                      }}
                    >
                      Sign Out
                    </button>
                  </li>
                </ul>
              </CSSTransition>
            </div>
          </header>
          <main className="dashboard-main">
            <div className="container">{props.children}</div>
          </main>
        </div>
      </div>
    </DashboardContext.Provider>
  );
};
