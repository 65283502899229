import classNames from 'classnames';
import { Loader } from '../utils/Loader';
import editIcon from '../../img/btn-icons/edit.svg';
import downloadIcon from '../../img/btn-icons/download.svg';
import linkIcon from '../../img/btn-icons/link.svg';

type Props = {
  type?: 'submit' | 'button';
  label: string;
  fill?: 'solid' | 'outline';
  color?: 'blue' | 'green' | 'red';
  hasArrow?: boolean;
  loading?: boolean;
  disabled?: boolean;
  locked?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  asLink?: boolean;
  to?: string;
  size?: 'large' | 'medium' | 'small' | 'extra-small';
  icon?: 'edit' | 'download' | 'link' | 'save' | 'plus' | 'purchase';
};

export const Btn = ({ fill = 'solid', color = 'blue', size = 'large', ...props }: Props) => {
  const innerContent = (
    <>
      {props.icon === 'edit' && <img className="icon" src={editIcon} alt="Edit icon" />}
      {props.icon === 'download' && <img className="icon" src={downloadIcon} alt="Download icon" />}
      {props.icon === 'link' && <img className="icon" src={linkIcon} alt="Link icon" />}
      {props.icon === 'save' && <span className="icon icon-save" />}
      {props.icon === 'plus' && <span className="icon icon-plus" />}
      {props.icon === 'purchase' && <span className="icon icon-purchase" />}
      <span className="label">{props.label}</span>
      {props.hasArrow && <span className="arrow icon-arrow_forward_ios"></span>}
      <Loader color="white" />
    </>
  );

  const classes = classNames({
    btn: true,
    arrow: props.hasArrow && !props.locked,
    icon: props.icon && !props.locked,
    loading: props.loading,
    locked: props.locked,
    [color]: true,
    [fill]: true,
    [size]: true,
  });

  if (props.asLink && !props.disabled && !props.locked)
    return (
      <a className={classes} href={props.to} target="_blank" rel="noreferrer">
        {innerContent}
      </a>
    );

  return (
    <button className={classes} type={props.type} disabled={props.disabled || props.loading || props.locked} onClick={props.onClick}>
      {props.locked ? <span className="label">Locked</span> : innerContent}
    </button>
  );
};
