import * as Yup from 'yup';
import { Link, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { TextInput } from '../../components/inputs/TextInput';
import { LoginLayout } from '../../components/layout/login-layout/LoginLayout';
import { Btn } from '../../components/inputs/Btn';
import { FormControlRow } from '../../components/inputs/form-parts/FormControlRow';
import { FormSubmitRow } from '../../components/inputs/form-parts/FormSubmitRow';
import { useState } from 'react';
import { AuthService } from '../../services/Auth.service';
import { AuthApi } from '../../services/api/AuthApi';
import { SITEMAP } from '../../config/SITEMAP';
import { InfoRow } from '../../components/inputs/form-parts/InfoRow';
import { Helmet } from 'react-helmet';
import { SeoService } from '../../services/SeoService';

export const LoginPage = () => {
  const [formError, setFormError] = useState('');
  const location = useLocation();

  return (
    <LoginLayout
      title="Log in"
      description={
        <>
          Don’t have an account? <Link to={SITEMAP.REGISTER}>Create it.</Link>
        </>
      }
      afterText={<Link to={SITEMAP.FORGOT_PASSWORD}>Forgot password?</Link>}
    >
      <Helmet>
        <title>{SeoService.getPageTitle('Log in')}</title>
      </Helmet>
      {(location.state as any)?.fromResetForm && <InfoRow type="success" message="Password sucessfully reset" />}
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={Yup.object({
          email: Yup.string().email('Invalid email address').required('Email is required'),
          password: Yup.string().required('Password is required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          AuthApi.login(values)
            .then((res) => {
              AuthService.login(res.data.access_token, res.data.user);
              setSubmitting(false);
            })
            .catch((err) => {
              setSubmitting(false);
              if (err.statusCode === 401) {
                // Unauthorized
                setFormError(err.message);
              } else {
                console.log(err);
                setFormError('Attempt to log in failed');
              }
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormControlRow>
              <TextInput label="E-mail address" placeholder="e.g. johndoe@example.com" name="email" autoComplete="username" type="email" />
            </FormControlRow>
            <FormControlRow>
              <TextInput label="Password" placeholder="Your password" name="password" type="password" autoComplete="current-password" />
            </FormControlRow>
            {formError && <InfoRow type="error" message={formError} />}
            <FormSubmitRow>
              <Btn type="submit" label="Login" hasArrow={true} loading={isSubmitting} />
            </FormSubmitRow>
          </Form>
        )}
      </Formik>
    </LoginLayout>
  );
};
