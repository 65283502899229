import moment from 'moment';
import * as Yup from 'yup';

const phoneRegExp = /^\d+$/;

export const FirstNameValidator = Yup.string().required('First name is mandatory');
export const LastNameValidator = Yup.string().required('Last name is mandatory');
export const EmailValidator = Yup.string().email('Invalid email address').required('Email is mandatory');
export const BirthdayValidator = Yup.date().test('Birthday', 'Must be older than 13 years', (value) => {
  if (!value) return true;
  let entered = moment(value);
  let now = moment();
  let difference = now.diff(entered, 'years');
  return difference >= 13;
});

export const StreetValidator = Yup.string().required('Street is mandatory');
export const PlaceValidator = Yup.string().required('Place is mandatory');
export const ZipValidator = Yup.string().required('ZIP is mandatory');
export const PhoneNumberValidator = Yup.string()
  .required('Phone number is mandatory')
  .min(10, 'Invalid phone number')
  .max(10, 'Invalid phone number')
  .matches(phoneRegExp, 'Invalid phone number');
export const UniqueValidatorCb = (values: any) => new Set(values).size === values.length;
