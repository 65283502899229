import { FullDivLoaderWrapper } from '../utils/full-div-loader/FullDivLoaderWrapper';

type PropsType = {
  children: React.ReactNode;
  controls: React.ReactNode;
  loading?: boolean;
};

export const MessageLayout = (props: PropsType) => {
  return (
    <div className="message-layout">
      <FullDivLoaderWrapper loading={props.loading || false} />
      {props.children}
      <div className="message-layout__controls">{props.controls}</div>
    </div>
  );
};
