import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { SITEMAP } from '../../../config/SITEMAP';

export const NotificationBarPlan = () => {
  const history = useHistory();

  return (
    <div
      className={classNames({
        'notification-bar': true,
        'notification-bar--error': true,
      })}
    >
      <div className="content">
        <span className="icon icon-attention"></span>
        <span className="message">Please choose a subscription plan to get full access to our services.</span>
      </div>
      <div className="actions">
        <button onClick={() => history.push(SITEMAP.CUSTOMIZE_PLAN)}>Choose a plan</button>
      </div>
    </div>
  );
};
