import { useField } from 'formik';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Loader } from '../utils/Loader';
import classNames from 'classnames';
import { TextInputWrapper } from './text-input-parts/TextInputWrapper';
import { TextInputLabel } from './text-input-parts/TextInputLabel';
import { TextInputError } from './text-input-parts/TextInputError';
import { FormikRichInputType } from './text-input-parts/FormikRichInput.type';
import { useState } from 'react';

export const PlaceInput = (props: FormikRichInputType) => {
  const [field, meta, helper] = useField(props);
  const [isSelectedFromDropdown, setIsSelectedFromDropdown] = useState(false);

  const handleChange = (address: string) => {
    helper.setValue(address);
    setIsSelectedFromDropdown(false);
  };

  const handleSelect = (address: any) => {
    helper.setValue(address);
    setIsSelectedFromDropdown(true);
  };

  const searchOptions = {
    types: ['(cities)'],
    componentRestrictions: { country: 'us' },
  };

  const showError = meta.touched && meta.error;

  return (
    <PlacesAutocomplete
      value={field.value}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      highlightFirstSuggestion
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        /**
         * Removing all input props that are in conflict with Formik
         */
        const filteredAutocompleteProps: any = getInputProps({
          placeholder: props.placeholder,
          className: 'location-search-input',
        });
        const autocompleteOnChange = filteredAutocompleteProps.onChange;
        const autocompleteOnBlur = filteredAutocompleteProps.onBlur;
        delete filteredAutocompleteProps.onChange;
        delete filteredAutocompleteProps.value;
        delete filteredAutocompleteProps.className;
        delete filteredAutocompleteProps.onBlur;
        delete filteredAutocompleteProps.autoComplete;

        return (
          <TextInputWrapper id={props.id || props.name}>
            <TextInputLabel for={props.id || props.name} label={props.label} required={props.required} />
            {loading && <Loader color="blue" />}
            <input
              id={props.id || props.name}
              autoComplete={props.autoComplete}
              onChange={(e: any) => {
                autocompleteOnChange(e);
                field.onChange(e);
              }}
              className={classNames({ error: showError })}
              value={field.value}
              onBlur={(e: any) => {
                if (!isSelectedFromDropdown) helper.setValue('');
                autocompleteOnBlur(e);
                field.onBlur(e);
              }}
              {...filteredAutocompleteProps}
            />
            <TextInputError showError={!!showError} error={meta.error} />

            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion, idx) => {
                let className = 'suggestion-item';
                if (suggestion.active) className += ' suggestion-item--active';
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </TextInputWrapper>
        );
      }}
    </PlacesAutocomplete>
  );
};
